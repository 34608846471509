
// ---Common-section-->>
$transition:all .3s ease-in-out;

// ---Font-section-->>
$reqular: "Avenir-NextRegular";
$bold: "Avenir-NextBold";
$medium: "Avenir-NextMedium";
$italic: "AvenirNext-italic";
$boldItalic: "AvenirNext-bold-italic";
$demiBold: "AvenirNext-DemiBold";

//color--section-->>
$primary: #d8463d;
$black:#000000;
$primaryGradient: linear-gradient(219.2deg, #ffc100 19.34%, #544516 82.49%);
$green:     #4cc93f;
$icon-base-size: 20px;
