@import "../common/breakpoint.scss";
@import "../variables.scss";
@import "../mixin.scss";

.new_login_wrapper {
    height: 100vh;
    background: transparent linear-gradient(180deg, #fffaed 0%, #fffdf8 39%, #fffefc 70%, #ffffff 100%) 0% 0% no-repeat padding-box;
    object-fit: contain;
.disableComment{
    pointer-events: none;
    cursor: pointer;
}
    .post-loading {
        position: absolute;
        left: 50%;
        top: 50%;
    }

    .publick_navbar {
        height: 101px;

        .login-btn {
            display: none;
        }

        @include breakpoint(upto-tablet) {
            height: auto;

            .navbar-brand {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 95%;
                margin: 0 auto;
                padding: .3rem 0 .8rem;
                border-bottom: 0.5px solid #CCCCCC;

                .logo_wrap {
                    position: static;
                }

                .login-btn {
                    display: block;
                    background: #D8463D 0% 0% no-repeat padding-box;
                    border-radius: 6px;
                    font-size: .875em;
                    text-transform: capitalize;
                    color: #fff;
                    padding: .17rem 1.1rem;
                    font-family: $demiBold;
                    @include breakpoint(upto-mobile-375){
                        font-size: .775em; 
                        padding: 0.16rem 0.77rem;
                    }
                }
            }
        }
    }

    .login_button_wraper {
        button {
            a {
                text-decoration: none;
                transition: $transition;

                &:hover {
                    color: #fff !important;
                    opacity: 0.89;
                    transition: $transition;
                }
            }
        }

        @include breakpoint(upto-mobile) {
            display: flex;
            flex-direction: column-reverse;
            flex-wrap: wrap;

            .login_box_wrap {
                width: 90%;
                margin: 0 auto;

                .card {
                    width: 100% !important;

                    h5 {
                        font-size: 23px;
                        margin-bottom: 11px;
                    }

                    form {
                        .form-control {
                            height: 36px;
                        }

                        .icon {
                            top: 61% !important;
                            width: 18px;
                            height: 18px;
                        }
                    }
                }
            }
        }

        .like_influ {
            position: absolute;
            bottom: 9rem;
            left: 6rem;
            border: none;
            border-radius: 1.5rem !important;
            box-shadow: none;
            background: transparent linear-gradient(254deg, #d8463d 0%, #f45c43 47%, #f3af41 100%) 0% 0% no-repeat padding-box !important;
            font-weight: 500;
            padding: 0.9rem;

            @include breakpoint(upto-mobile) {
                position: relative;
                left: inherit;
                bottom: inherit;
                width: 80%;
                margin: 2rem auto;
            }

            a {
                display: grid;
                width: 100%;
                grid-template-columns: max-content 1fr;
                align-items: center;
                grid-gap: 0.8rem;
            }
        }
    }

    .wrapper {
        height: auto;
        background: transparent linear-gradient(180deg,
                #fffaed 0%,
                #fffdf8 39%,
                #fffefc 70%,
                #ffffff 100%) 0% 0% no-repeat padding-box;

        @include breakpoint (upto-mobile) {
            width: 95%;
            margin: 0 auto;
        }

        .app_nav {
            margin: 0 auto;
            z-index: 1051;

            @include respond(mobile) {
                width: 100%;
                // border-bottom: 1px solid #e4e4e4;
                background: #fffaed;
                margin-right: 0 !important;
            }
        }

        .main_body {
            max-height: calc(100vh - 101px);
            overflow-y: auto;
            height: 100%;

            .profile_wrap {
                padding: 0px 20px;
                border-radius: 12px 12px 0 0;
                margin-bottom: 10px;
                height: 100%;

                @include respond(mobile) {
                    padding: 10px;
                }

                .profile_body {
                    background: #ffffff 0% 0% no-repeat padding-box;

                    .tab-content {
                        height: 93%;
                        overflow: auto;
                    }

                    ul {
                        li {
                            button {
                                padding: 5px;
                            }
                        }
                    }
                }

                .bio_box {
                    min-height: 40px;
                }

                .user_bio {
                    max-height: 40vh;

                    @include breakpoint(upto-Semi-XL-wide-screen) {
                        max-height: 33vh;

                        @include breakpoint(upto-tablet-800) {
                            max-height: 40vh;
                        }
                    }

                    .no_info_wrap {
                        .user_has {
                            color: #707070;
                            font-size: 18px;
                            line-height: 25px;
                        }
                    }

                    .inner_bio {
                        color: #231f20;
                        font: normal normal medium 16px/28px $medium;
                    }

                    p {
                        // height: 145px;
                        white-space: break-spaces;
                        line-height: 2 !important;
                        font-size: 1em;

                        @include breakpoint(upto-landscape-tablet) {
                            // height: 70px;

                            @include breakpoint(upto-tablet) {
                                // height: 130px;

                                @include breakpoint(upto-mobile) {
                                    font-size: 0.875em;
                                    // height: 125px;

                                    @include breakpoint(upto-mobile-mid) {
                                        // height: 110px;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            @include respond(mobile) {
                max-height: 100%;
            }
        }

        .feed_row {
            width: 100%;
            display: flex;
            margin-top: 0;
            gap: 20px;

            .right_footer {
                position: fixed;
                bottom: 0.7rem;
                width: 18.5rem;
                background: #fff;

                ul li {
                    position: relative;

                    &:hover {
                        transition: $transition;
                        opacity: 0.89;

                        &::before {
                            content: "";
                            width: 100%;
                            height: 2px;
                            background: #000;
                            position: absolute;
                            left: 0;
                            bottom: 0;
                            animation: borderAnime 0.4s linear;
                            transition: $transition;
                        }
                    }

                    @keyframes borderAnime {
                        0% {
                            width: 0;
                            transition: $transition;
                        }

                        100% {
                            width: 100%;
                            transition: $transition;
                        }
                    }
                }

                .feed_grid_row.feed-loading-wrap {
                    .css-tuh9u2 {
                        display: block;
                    }
                }

                .feed_grid_row {
                    margin-bottom: 20px;

                    @include breakpoint(upto-XXL-wide-screen) {
                        column-width: 320px;

                        @include breakpoint(upto-XL-wide-screen) {
                            column-gap: 20px;
                            column-width: 296px;

                            @include breakpoint(upto-X-wide-screen) {
                                column-width: 260px;

                                @include breakpoint(upto-mobile) {
                                    columns: 170px;
                                    column-gap: 12px !important;

                                    @include breakpoint(upto-mobile-mid) {
                                        columns: 150px;

                                        @include breakpoint(upto-mobile-375) {
                                            columns: 140px;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .css-tuh9u2 {
                        page-break-inside: avoid;
                        -moz-column-break-inside: avoid;
                        break-inside: avoid;
                        margin-bottom: 1rem;
                        // display: inline-flex;

                        @include breakpoint(upto-tablet) {
                            white-space: break-spaces;
                        }
                    }

                    @include respond(mobile) {
                        margin-bottom: 10px;
                        grid-gap: 5px;
                    }

                    .feed_grid_col {
                        background: #ffffff 0% 0% no-repeat padding-box;
                        box-shadow: 0px 3px 6px #00000029;
                        border-radius: 18px;
                        padding: 8px;
                        position: relative;

                        @include respond(mobile) {
                            margin: 0;

                            .post_head {
                                .MuiButtonBase-root {
                                    .icon {
                                        width: 18px !important;
                                    }
                                }

                                .post_head_avtar {
                                    span {
                                        margin: 5px 4px 5px 0 !important;
                                    }
                                }
                            }
                        }

                        .feed_head {
                            position: absolute;
                            top: 0;
                            top: 0;
                            z-index: 1;
                            width: 100%;

                            h5 {
                                color: #fff;
                                font-family: $bold;
                                font-size: 20px;
                                line-height: 26px;
                                -webkit-line-clamp: 2;
                                -webkit-box-orient: vertical;
                                overflow: hidden;
                                height: 45px;

                                @include respond(smScreen) {
                                    font-size: 16px;
                                    line-height: 22px;
                                    font-family: $demiBold;
                                }

                                @include respond(mobile) {
                                    font-size: 13px;
                                    line-height: 16px;
                                }
                            }

                            .media {
                                display: flex;

                                img {
                                    height: 50px;
                                    width: 50px;
                                    border-radius: 50%;
                                    margin-right: 10px;
                                }

                                .media_details {
                                    margin-left: 15px;

                                    h5 {
                                        color: #363636;
                                        font-size: 16px;
                                        font-family: $medium;
                                        line-height: 20px;
                                        margin-bottom: 0px;
                                    }

                                    p {
                                        font-size: 14px;
                                        font-family: $medium;
                                        line-height: 19px;
                                        margin-bottom: 0;
                                    }
                                }
                            }
                        }

                        .feed_body {
                            .feed_content {
                                width: 100%;
                                position: relative;
                                @include ZoomScaller;
                                border-radius: 12px;

                                &:before {
                                    content: "";
                                    position: absolute;
                                    left: 0;
                                    right: 0;
                                    height: 110px;
                                    transform: matrix(-1, 0, 0, -1, 0, 0);
                                }

                                &:after {
                                    content: "";
                                    position: absolute;
                                    left: 0;
                                    right: 0;
                                    height: 150px;
                                    background: linear-gradient(to top,
                                            rgba(0, 0, 0, 0.8) 0%,
                                            rgba(0, 0, 0, 0) 100%);
                                    bottom: 0;
                                    border-radius: 12px;
                                }

                                img {
                                    width: 100%;
                                    cursor: pointer;
                                    border-radius: 12px;
                                    aspect-ratio: 1/2;
                                }

                                .users {
                                    border-radius: 12px;
                                    position: absolute;
                                    bottom: 0;
                                    z-index: 1;
                                    left: 0;
                                    display: flex;
                                    justify-content: space-between;
                                    width: 100%;
                                    padding: 0 10px 10px;
                                    align-items: flex-end;

                                    ul {
                                        li {
                                            display: flex;
                                            align-items: center;

                                            &:first-child {
                                                margin-bottom: 10px;
                                            }

                                            @include respond(mobile) {
                                                margin-bottom: 6px;
                                            }

                                            .sb-avatar {
                                                @include respond(smScreen) {
                                                    height: 20px !important;
                                                    width: 20px !important;
                                                }

                                                @include respond(mobile) {
                                                    height: 14px !important;
                                                    width: 14px !important;
                                                }

                                                .sb-avatar__text {
                                                    @include respond(mobile) {
                                                        height: 14px !important;
                                                        width: 14px !important;
                                                        font-size: 10px;
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    .views_count {
                                        color: #fff;
                                        font-family: $reqular;
                                        font-weight: bold;
                                        display: flex;
                                        align-items: center;
                                        margin-right: 0;

                                        @include respond(mobile) {
                                            font-size: 9px;
                                            transform: translateY(-10px);
                                        }

                                        .icon {
                                            margin-right: 5px;

                                            @include respond(mobile) {
                                                height: 14px;
                                                width: 14px;
                                            }
                                        }
                                    }

                                    strong {
                                        color: #fff;
                                        margin-left: 7px;

                                        @include respond(smScreen) {
                                            font-size: 12px;
                                        }

                                        @include respond(mobile) {
                                            font-size: 10px;
                                            line-height: 14px;
                                            white-space: nowrap;
                                            overflow: hidden;
                                            text-overflow: ellipsis;
                                            display: inline-block;
                                            max-width: 59px;
                                        }
                                    }
                                }
                            }

                            .half {
                                height: 50%;

                                &:first-child {
                                    img {
                                        border-top-left-radius: 10px;
                                        border-top-right-radius: 10px;
                                    }
                                }

                                &:last-child {
                                    img {
                                        border-bottom-left-radius: 10px;
                                        border-bottom-right-radius: 10px;
                                    }
                                }

                                img {
                                    width: 100%;
                                    height: 100%;
                                }
                            }

                            .feed_bottom {
                                .comment_wrap_box {
                                    padding: 0px 5px;

                                    .like_comment_details {
                                        height: auto;
                                        margin: 5px 0px;
                                        font-family: $reqular;
                                        font-weight: bold;
                                    }

                                    .view_all_text {
                                        font-size: 12px;
                                        font-family: $demiBold;
                                        line-height: 16px;
                                        margin-bottom: 15px;
                                        cursor: pointer;
                                    }

                                    .post_title {
                                        margin: 10px 0px;
                                        font-size: 13px;
                                        font-family: $medium;
                                        line-height: 18px;
                                    }

                                    .comment_body {
                                        max-height: 100%;
                                        min-height: 100%;
                                    }

                                    .comment_ul {
                                        display: inline-block;
                                    }
                                }

                                @include respond(mobile) {
                                    display: none;
                                }

                                ul {
                                    display: flex;

                                    li {
                                        padding: 5px 15px 0 0;
                                        cursor: pointer;

                                        .icon {
                                            height: 30px;
                                            width: 30px;
                                        }
                                    }
                                }

                                .input-group {
                                    border: 0.5px solid #cccccca3;
                                    border-radius: 30px;
                                    background: #ebebeb 0% 0% no-repeat padding-box;

                                    .input-group-text {
                                        background-color: transparent;
                                        border: 0;
                                        padding: 0 4px 0 4px;

                                        h5 {
                                            margin-bottom: 0;
                                            font-size: 16px;
                                            font-family: $demiBold;
                                            cursor: pointer;
                                            margin-right: 10px;
                                        }

                                        .bi {
                                            font-size: 25px;
                                            line-height: 0px;
                                            cursor: pointer;
                                        }
                                    }

                                    .form-control {
                                        font-size: 11px;
                                        font-weight: bold;
                                        color: #707070;
                                        border: 0;
                                        height: 38px;
                                        padding: 6px;
                                        background: #ebebeb 0% 0% no-repeat padding-box;
                                    }
                                }
                            }
                        }
                    }
                }

                .feed_grid_row::-webkit-scrollbar {
                    display: none;
                }

                @include breakpoint(upto-mobile-mid) {
                    .post_head_un {
                        width: 57px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;

                        @include breakpoint(upto-mobile-375) {
                            width: 55px;
                        }
                    }
                }
            }

            @include respond(mobile) {
                padding: 0;
            }

            .feed_col_left {
                height: 100%;
                max-width: 273px;
                min-width: 300px;
                display: flex;
                padding: 0px;
                overflow-y: scroll;
                justify-content: space-between;
                flex-direction: column;
                top: 0;

                @include respond(mobile) {
                    display: none;
                }

                @include respond(smScreen3) {
                    display: none;
                }

                .vertical_nav_wrap {
                    height: 0;
                    padding: 0;
                }
            }

            .feed_col_left::-webkit-scrollbar {
                display: none;
            }

            .is_shadow {
                background: none !important;
                box-shadow: none !important;
                padding: 0px;
                border-radius: 14px 14px 0 0;

                .vertical_nav_container {
                    height: max-content !important;

                    &.nav {
                        display: inherit;
                    }
                }

                .right_footer {
                    padding: 0 0.9rem;
                }
            }

            .feed_col_middle {
                width: 68%;
                flex: 1;
                margin-left: 1.5rem;

                @include respond(mobile) {
                    width: 100%;
                    margin-left: 0;
                }

                @include respond(smScreen3) {
                    width: 100%;
                    margin-left: 0;
                }
            }

            .feed_col_right {
                top: 0;
                width: 25%;
                background: #ffffff 0% 0% no-repeat padding-box;
                box-shadow: 0px 3px 6px #00000029;
                border-radius: 12px;
                height: calc(100vh - 115px);
                padding: 15px 9px 15px 15px;
                display: flex;
                flex-direction: column;

                @include respond(mobile) {
                    display: none;
                }

                .notification_wrap {
                    flex: 1;
                }
            }
        }

        .feed_grid_row.feed-loading-wrap {
            .css-tuh9u2 {
                display: block;
            }
        }

        .feed_grid_row {
            width: 66%;
            margin-bottom: 20px;
            row-gap: 2px;

            @include breakpoint(upto-XXL-wide-screen) {
                column-width: 320px;

                @include breakpoint(upto-XL-wide-screen) {
                    column-gap: 20px;
                    column-width: 296px;

                    @include breakpoint(upto-X-wide-screen) {
                        width: 100%;
                        column-width: 260px;

                        @include breakpoint(upto-mobile) {
                            width: 100%;
                            columns: 170px;
                            column-gap: 12px !important;

                            @include breakpoint(upto-mobile-mid) {
                                columns: 150px;

                                @include breakpoint(upto-mobile-375) {
                                    columns: 140px;
                                }
                            }
                        }
                    }
                }
            }

            .css-tuh9u2 {
                page-break-inside: avoid;
                -moz-column-break-inside: avoid;
                break-inside: avoid;
                margin-bottom: 1rem;
                // display: inline-flex;

                @include breakpoint(upto-tablet) {
                    white-space: break-spaces;
                }
            }

            @include respond(mobile) {
                margin-bottom: 10px;
                grid-gap: 5px;
            }

            .feed_grid_col {
                background: #ffffff 0% 0% no-repeat padding-box;
                box-shadow: 0px 3px 6px #00000029;
                border-radius: 18px;
                padding: 8px;
                position: relative;

                @include respond(mobile) {
                    margin: 0;

                    .post_head {
                        .MuiButtonBase-root {
                            .icon {
                                width: 18px !important;
                            }
                        }

                        .post_head_avtar {
                            span {
                                margin: 5px 4px 5px 0 !important;
                            }
                        }
                    }
                }

                .feed_head {
                    position: absolute;
                    top: 0;
                    top: 0;
                    z-index: 1;
                    width: 100%;

                    h5 {
                        color: #fff;
                        font-family: $bold;
                        font-size: 20px;
                        line-height: 26px;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        height: 45px;

                        @include respond(smScreen) {
                            font-size: 16px;
                            line-height: 22px;
                            font-family: $demiBold;
                        }

                        @include respond(mobile) {
                            font-size: 13px;
                            line-height: 16px;
                        }
                    }

                    .media {
                        display: flex;

                        img {
                            height: 50px;
                            width: 50px;
                            border-radius: 50%;
                            margin-right: 10px;
                        }

                        .media_details {
                            margin-left: 15px;

                            h5 {
                                color: #363636;
                                font-size: 16px;
                                font-family: $medium;
                                line-height: 20px;
                                margin-bottom: 0px;
                            }

                            p {
                                font-size: 14px;
                                font-family: $medium;
                                line-height: 19px;
                                margin-bottom: 0;
                            }
                        }
                    }
                }

                .feed_body {
                    .feed_content {
                        width: 100%;
                        position: relative;
                        @include ZoomScaller;
                        border-radius: 12px;

                        &:before {
                            content: "";
                            position: absolute;
                            left: 0;
                            right: 0;
                            height: 110px;
                            transform: matrix(-1, 0, 0, -1, 0, 0);
                        }

                        &:after {
                            content: "";
                            position: absolute;
                            left: 0;
                            right: 0;
                            height: 150px;
                            background: linear-gradient(to top,
                                    rgba(0, 0, 0, 0.8) 0%,
                                    rgba(0, 0, 0, 0) 100%);
                            bottom: 0;
                            border-radius: 12px;
                        }

                        img {
                            width: 100%;
                            cursor: pointer;
                            border-radius: 12px;
                            aspect-ratio: 1/2;
                        }

                        .users {
                            border-radius: 12px;
                            position: absolute;
                            bottom: 0;
                            z-index: 1;
                            left: 0;
                            display: flex;
                            justify-content: space-between;
                            width: 100%;
                            padding: 0 10px 10px;
                            align-items: flex-end;

                            ul {
                                li {
                                    display: flex;
                                    align-items: center;

                                    &:first-child {
                                        margin-bottom: 10px;
                                    }

                                    @include respond(mobile) {
                                        margin-bottom: 6px;
                                    }

                                    .sb-avatar {
                                        @include respond(smScreen) {
                                            height: 20px !important;
                                            width: 20px !important;
                                        }

                                        @include respond(mobile) {
                                            height: 14px !important;
                                            width: 14px !important;
                                        }

                                        .sb-avatar__text {
                                            @include respond(mobile) {
                                                height: 14px !important;
                                                width: 14px !important;
                                                font-size: 10px;
                                            }
                                        }
                                    }
                                }
                            }

                            .views_count {
                                color: #fff;
                                font-family: $reqular;
                                font-weight: bold;
                                display: flex;
                                align-items: center;
                                margin-right: 0;

                                @include respond(mobile) {
                                    font-size: 9px;
                                    transform: translateY(-10px);
                                }

                                .icon {
                                    margin-right: 5px;

                                    @include respond(mobile) {
                                        height: 14px;
                                        width: 14px;
                                    }
                                }
                            }

                            strong {
                                color: #fff;
                                margin-left: 7px;

                                @include respond(smScreen) {
                                    font-size: 12px;
                                }

                                @include respond(mobile) {
                                    font-size: 10px;
                                    line-height: 14px;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: inline-block;
                                    max-width: 59px;
                                }
                            }
                        }
                    }

                    .half {
                        height: 50%;

                        &:first-child {
                            img {
                                border-top-left-radius: 10px;
                                border-top-right-radius: 10px;
                            }
                        }

                        &:last-child {
                            img {
                                border-bottom-left-radius: 10px;
                                border-bottom-right-radius: 10px;
                            }
                        }

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .feed_bottom {
                        .comment_wrap_box {
                            padding: 0px 5px;

                            .like_comment_details {
                                height: auto;
                                margin: 5px 0px;
                                font-family: $reqular;
                                font-weight: bold;
                            }

                            .view_all_text {
                                font-size: 12px;
                                font-family: $demiBold;
                                line-height: 16px;
                                margin-bottom: 15px;
                                cursor: pointer;
                            }

                            .post_title {
                                margin: 10px 0px;
                                font-size: 13px;
                                font-family: $medium;
                                line-height: 18px;
                            }

                            .comment_body {
                                max-height: 100%;
                                min-height: 100%;
                            }

                            .comment_ul {
                                display: inline-block;
                            }
                        }

                        @include respond(mobile) {
                            display: none;
                        }

                        ul {
                            display: flex;

                            li {
                                padding: 5px 15px 0 0;
                                cursor: pointer;

                                .icon {
                                    height: 30px;
                                    width: 30px;
                                }
                            }
                        }

                        .input-group {
                            border: 0.5px solid #cccccca3;
                            border-radius: 30px;
                            background: #ebebeb 0% 0% no-repeat padding-box;

                            .input-group-text {
                                background-color: transparent;
                                border: 0;
                                padding: 0 4px 0 4px;

                                h5 {
                                    margin-bottom: 0;
                                    font-size: 16px;
                                    font-family: $demiBold;
                                    cursor: pointer;
                                    margin-right: 10px;
                                }

                                .bi {
                                    font-size: 25px;
                                    line-height: 0px;
                                    cursor: pointer;
                                }
                            }

                            .form-control {
                                font-size: 11px;
                                font-weight: bold;
                                color: #707070;
                                border: 0;
                                height: 38px;
                                padding: 6px;
                                background: #ebebeb 0% 0% no-repeat padding-box;
                            }
                        }
                    }
                }
            }
        }

        .feed_grid_row::-webkit-scrollbar {
            display: none;
        }

        @include breakpoint(upto-mobile-mid) {
            .post_head_un {
                width: 57px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                @include breakpoint(upto-mobile-375) {
                    width: 55px;
                }
            }
        }
    }

    .wrap {
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        flex-direction: column;
        flex: 1;
        padding-right: 215px;
        padding-top: 50px;

        @include respond(bigScreen) {
            padding-top: 60px;
            height: calc(100vh - 141px);
        }

        @include respond(midScreen) {
            height: calc(100vh - 141px);
            padding-top: 60px;
        }

        @include respond(mobile) {
            height: calc(100vh - 374px) !important;
        }

        .chakra-button {
            box-shadow: none;
            text-transform: uppercase;
            background: #4284f3 0 0 no-repeat padding-box;
            border-radius: 25px;
            border: 0;
            height: 40px;
            color: #fff;
            width: 100%;
            font-size: 12px;
            font-family: $demiBold;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
            line-height: 0;
        }

        .or_text {
            text-align: center;
            color: #231f20;
            font-size: 18px;
            line-height: 25px;
            font-family: $medium;
            margin-top: 11px;
            margin-bottom: 5px;
        }

        .forgot_wrap {
            .form-check-label {
                font-family: $medium;
                font-size: 14px;
                line-height: 16px;
            }

            a {
                font-family: $demiBold;
            }
        }
    }

    .card {
        width: 300px;
        height: auto;
        padding: 0;
        background-color: none;
        background: none;
        box-shadow: none;

        .text_login {
            font: normal normal 26px/32px $bold;
        }

        @include respond(smScreen) {
            width: 300px;
            height: auto;
        }

        button {
            border-radius: 25px !important;
            width: 100%;
            font: normal normal 10px/12px $demiBold;
        }

        .form-label {
            font: normal normal 12px/16px $medium;
        }

        .form-check {
            color: #707070;
            font: normal normal 11px/21px $medium;

            label {
                margin-left: 0.3em;
            }
        }

        .form-check-input {
            width: 1.2em;
            height: 1.2em;
        }

        .form-control {
            border-radius: 20px;
            font-size: 14px;
            font: normal normal 14px/19px $medium;
            line-height: 19px;
            color: #363636;

            input {
                height: 40px;
            }
        }

        h5 {
            font-size: 30px;
            margin-bottom: 23px;
            font-weight: bold;
            position: relative;
            font-family: $bold;

            &:before {
                content: "";
                position: absolute;
                left: -19px;
                height: 70px;
                width: 2px;
                background-color: $primary;
                top: -32px;

                @include respond(smScreen) {
                    top: -5px;
                }
            }
        }
    }

    .not_account_wrap {
        text-align: center;
        padding-top: 28px;
        width: 360px;

        a {
            color: #363636;
            font-size: 16px;
            text-decoration: none;
            font-family: $medium;

            strong {
                position: relative;

                &::before {
                    content: "";
                    position: absolute;
                    bottom: -5px;
                    width: 100%;
                    height: 2px;
                    background: #d8463d;
                }
            }
        }
    }

    .app_get_wrap {
        text-align: center;
        padding-top: 20px;

        width: 360px;
        display: flex;
        justify-content: center;

        @include respond(bigScreen) {
            padding-top: 40px;
        }

        @include respond(midScreen) {
            padding-top: 15px;
        }

        @include respond(smScreen) {
            width: 300px;
        }

        .download_app {
            display: flex;

            img {
                height: 35px;

                @include respond(smScreen) {
                    height: 28px;
                }
            }
        }

        h5 {
            color: #363636;
            font-size: 16px;
            text-decoration: none;
            font-family: $medium;
        }
    }
}

.mobile-login-backdrop {
    filter: brightness(0.3);
    opacity: .88 !important;
    background: url("../../img/modal_bg.png");
}

.mobile-login-wrapper {
    .card {
        box-shadow: inherit;

        .login-logo {
            width: 100%;
            display: flex;
            justify-content: center;

            .logo_wrap {
                width: max-content;
            }
        }

        form {
            margin-top: 1rem;

            .form-label {
                margin: 0.8rem 0;
            }

            .form-control {
                height: max-content;
            }

            p.icon.eye_hidden,
            p.icon.eye {
                right: 10px !important;
                top: 66% !important;
                height: 19px !important;
            }

            .submit-btn {
                width: 100%;
                background: #d8463d;
                color: #fff;
                font-size: 1em;
                text-transform: capitalize;
                font-family: $reqular;
                border-radius: 100px;
                margin: .5rem 0;
            }
        }
    }

    .modal-content {
        width: 95%;
        margin: 0 auto;
        border-radius: 1rem;

        .about-mobile-login {
            p {
                font-size: .775em;
                text-transform: capitalize;
                color: #707070;
                font-family: $reqular;
                text-align: center;
                margin: .4rem 0 1rem !important;
            }

            .social-login-link-wrap {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 1rem;

                a {
                    img {
                        height: 33px;
                    }
                }
            }
        }
    }

    @include breakpoint (upto-tablet) {
        .close_modal {
            right: 10px !important;
            top: -5px !important;

            .cross:before {
                filter: brightness(.5);
                width: 17px;
            }
        }
    }
}

.login-modal {
    @include breakpoint(upto-mobile) {
        .share-post-wrap {
            .share-post-container {
                width: 100%;
                .share_post_size{
                    height: calc(100vh - 0px) !important;
                }
            }

            .player_row .player_col.right {
                border-top-left-radius: 0;
            }
        }

        .modal-body {
            padding: 0;
        }
    }
}