.icon {
  cursor: pointer;
  width: 24px;
  height: 24px;
  position: relative;
  display: inline-block;
}

.icon_sm {
  cursor: pointer;
  width: 16px;
  height: 16px;
  position: relative;
  display: inline-block;
}

.icon-md {
  cursor: pointer;
  width: 55px;
  height: 50px;
  position: relative;
  display: inline-block;
}
.icon-lg {
  cursor: pointer;
  width: 100px;
  height: 100px;
  position: relative;
  display: inline-block;
}

.icon,
.icon-lg,
.icon_sm,
.icon-auction,
.icon-md {
  &:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    background-repeat: no-repeat;
    background-position: center;
    left: 0;
    top: 0;
    background-size: contain;
  }
}

.eye {
  &:before {
    background-image: url(../img/icons/view.png);
  }
}

.left_button {
  &:before {
    background-image: url(../img/icons/left.svg);
  }
}

.right_button {
  &:before {
    background-image: url(../img/icons/right.svg);
  }
}
.eye_hidden {
  &:before {
    background-image: url(../img/icons/hide.png);
  }
}
.threedot_icon {
  &:before {
    background-image: url(../img/icons/threesots.svg);
  }
}
.threedot_icon_story {
  &:before {
    background-image: url(../img/icons/threedotsWhite.svg);
  }
}

.feeds {
  &:before {
    background-image: url(../img/icons/feeds.svg);
  }
}
.search {
  &:before {
    background-image: url(../img/icons/search.svg);
  }
}
.notification {
  &:before {
    background-image: url(../img/icons/notification.svg);
  }
}
.comment {
  &:before {
    background-image: url(../img/icons/Comment.svg);
  }
}
.comment_share {
  &:before {
    background-image: url(../img/icons/comment_share.svg);
  }
}
.follow_share {
  &:before {
    background-image: url(../img/icons/follow.svg);
  }
}
.like {
  &:before {
    background-image: url(../img/icons/Like.svg);
  }
}
.liked {
  &:before {
    background-image: url(../img/icons/liked.svg);
  }
}
.login_to_like {
  &:before {
    background-image: url(../img/icons/login_to_like.svg);
  }
}
.login_to_comment {
  &:before {
    background-image: url(../img/icons/login_to_like.svg);
  }
}
.cross {
  &:before {
    background-image: url(../img/icons/cross.svg);
  }
}
.heart-like {
  &:before {
    background-image: url(../img/icons/heart-like.svg);
  }
}
.share {
  &:before {
    background-image: url(../img/icons/share.svg);
  }
}
.share_story {
  &:before {
    background-image: url(../img/icons/shareWhite.svg);
  }
}
.profile {
  &:before {
    background-image: url(../img/icons/profile_icon.svg);
  }
}
.settings {
  &:before {
    background-image: url(../img/icons/settings_icon.svg);
  }
}
.sound {
  &:before {
    background-image: url(../img/icons/sound.svg);
  }
}
.sound_mute {
  &:before {
    background-image: url(../img/icons/muteSound.svg);
  }
}

.logout {
  &:before {
    background-image: url(../img/icons/logout_icon.svg);
  }
}
.bio {
  &:before {
    background-image: url(../img/icons/bio.svg);
  }
}
.feeds_sm {
  &:before {
    background-image: url(../img/icons/feeds_sm.svg);
  }
}
.skills {
  &:before {
    background-image: url(../img/icons/skills.svg);
  }
}
.tagged {
  &:before {
    background-image: url(../img/icons/tagged.svg);
  }
}
.tags {
  &:before {
    background-image: url(../img/icons/Tags.svg);
  }
}
.twitter {
  &:before {
    background-image: url(../img/icons/twitter.svg);
  }
}
.instagram {
  &:before {
    background-image: url(../img/icons/social/instagram.svg);
  }
}
.camera_icon {
  &:before {
    background-image: url(../img/icons/camera_icon.svg);
  }
}
.view {
  &:before {
    background-image: url(../img/icons/Views_icon.svg);
  }
}
.empty_notification {
  &:before {
    background-image: url(../img/icons/empty_notification.svg);
  }
}
.edit_profile {
  &:before {
    background-image: url(../img/icons/edit_profile.svg);
  }
}
.help {
  &:before {
    background-image: url(../img/icons/help.svg);
  }
}
.lock {
  &:before {
    background-image: url(../img/icons/lock.svg);
  }
}
.delete {
  &:before {
    background-image: url(../img/icons/delete.svg);
  }
}
.search_grey {
  &:before {
    background-image: url(../img/icons/Search_grey.svg);
  }
}
.twitter_md {
  &:before {
    background-image: url(../img/icons/social/twitter.svg);
  }
}
.facebook_md {
  &:before {
    background-image: url(../img/icons/social/facebook.svg);
  }
}
.instagram_md {
  &:before {
    background-image: url(../img/icons/social/instagram.svg);
  }
}
.copylink_md {
  &:before {
    background-image: url(../img/icons/social/copylink.svg);
  }
}
.whatsapp_md {
  &:before {
    background-image: url(../img/icons/social/whatsapp.svg);
  }
}
.linkedin_md {
  &:before {
    background-image: url(../img/icons/social/linkedin.svg);
  }
}
.email_md {
  &:before {
    background-image: url(../img/icons/social/email.svg);
  }
}

.video_button {
  &:before {
    background-image: url(../img/icons/videoButton.svg);
  }
}

.twitter_md_share {
  &:before {
    background-image: url(../img/icons/social/twitter_share.svg);
  }
}
.facebook_md_share {
  &:before {
    background-image: url(../img/icons/social/facebook_share.svg);
  }
}
.instagram_md_share {
  &:before {
    background-image: url(../img/icons/social/copy_link.svg);
  }
}
.linkedin_md_share {
  &:before {
    background-image: url(../img/icons/social/linkedin_share.svg);
  }
}
.mail_md_share {
  &:before {
    background-image: url(../img/icons/social/mail_share.svg);
  }
}

.checked_md {
  &:before {
    background-image: url(../img/icons/social/checked.svg);
  }
}
.professional {
  &:before {
    background-image: url(../img/icons/professional.svg);
  }
}
.expert {
  &:before {
    background-image: url(../img/icons/expert.svg);
  }
}
.premium {
  &:before {
    background-image: url(../img/icons/premium.svg);
  }
}
