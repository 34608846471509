@media (max-width: 1680px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 998px;
  }
}

@media (max-width: 1367px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1110px;
  }
}

@media (max-width: 767px) {
  #root,
  body,
  html {
    height: auto;
  }
  .login_wrapper {
    .logo_wrap {
      left: 0;
      top: 0;
      position: relative;
      padding-top: 29px;
      padding-left: 24px;
      &:after {
        content: "";
        position: absolute;
        height: 1px;
        background: #7d7d7d;
        width: 85%;
        left: 26px;
        bottom: -25px;
      }
    }
    background: transparent
      linear-gradient(
        180deg,
        #fffaed 0%,
        #fffdf8 39%,
        #fffefc 70%,
        #ffffff 100%
      )
      0% 0% no-repeat padding-box;
    .wrap {
      padding-right: 0;
      justify-content: center;
      align-items: center;
    }
    .not_account_wrap,
    .app_get_wrap {
      display: none;
    }
  }
  .bottom_nav {
    left: 0;
    border-top: 1px solid #ddd;
    padding-left: 15px;
    padding-top: 20px;
    top: 0;
    position: relative;
    h5 {
      display: none;
    }
    .nav {
      display: inline-block;
      .nav-item {
        width: 50%;
        float: left;
      }
    }
  }
  .public_wrapper .body {
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;
  }

  .help_wrapper .body {
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;
  }
  footer {
    border-top: 1px solid #7d7d7d;

    hr {
      display: none;
    }
    .container {
      width: 100%;
    }
    .nav {
      display: inline-block;
      height: 100%;
      .nav-item {
        width: 50%;
        float: left;
        .nav-link select {
          display: none;
        }
      }
    }
    .app_get_wrap {
      display: none;
    }
    .bottom_footer {
      padding-left: 15px;
    }
  }
  .help .body h5 {
    font-size: 25px;
    line-height: 33px;
    margin-bottom: 20px;
  }
  .help .body .input-group .btn-primary {
    height: 31px;
    width: 82px;
    font-size: 13px;
  }
  .help .body .input-group .form-control {
    font-size: 14px;
  }
}
