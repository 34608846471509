 @import "../variables.scss";
 @import "../mixin.scss";
 @import "../common/breakpoint.scss";

 .privacy-main-wrapper {
     .publick_navbar {
         position: fixed;
         top: 0;
         left: 0;
         width: 20%;
         background: #fffaee;
         z-index: 99;

         @include breakpoint(upto-mobile) {
             width: 100%;
             height: 57px; 
         }
     }

     footer {
         position: fixed !important;
         bottom: 0;
         width: 100%;

         @include breakpoint(upto-mobile) {
             z-index: 99;
             position: static!important;
         }
     }

     @include breakpoint(upto-mobile) {
         .privacy_page {
             width: 100%;

             .privacy-grid {
                 grid-template-columns: 1fr !important;

                 aside {
                     display: none !important;
                 }

                 .privacy-content-wrapper {
                     margin-left: 0 !important;
                 }
             }
         }

         .desktop-privacy {
             display: none;
         }

         .mobile-privacy-wrapper {
             display: block;
             width: 95%;
             margin: 0 auto;
             height: max-content !important;
         }
     }

 }

 .privacy_page  {
     width: 80%;
     margin: 6rem auto 1rem;

     @include breakpoint(upto-tablet) {
         width: 90%;
         margin: 4rem auto 1rem;

         @include breakpoint(upto-mobile) {
             .container-wrap {
                 width: 100%;
             }
         }
     }

     .privacy-grid {
         display: grid;
         grid-template-columns: 200px 1fr;
         grid-gap: 0 3rem;
         margin: 2rem 0;

         @include breakpoint(upto-tablet) {
             grid-template-columns: 159px 1fr;
             grid-gap: 0 1rem;
             margin: .5rem 0 2rem;
         }

         aside {
             width: 100%;
             display: flex;
             align-items: center;
             flex-wrap: wrap;

             ul {
                 position: fixed;
                 width: 226px;
                 height: calc(100vh - 380px);
                 z-index: 80;

                 @include breakpoint(upto-tablet) {
                     width: 175px;
                     height: calc(100vh - 190px);
                 }

                 li {
                     list-style-type: decimal;
                     cursor: pointer;
                     font-family: $reqular;
                     font-weight: 600;

                     a {
                         font-size: 0.8125em;
                         text-transform: capitalize;
                         color: #000;
                         cursor: pointer;
                         padding: .3rem 0;
                         display: block;
                     }
                 }
             }
         }

         .privacy-content-wrapper {
             width: 100%;
             height: calc(100vh - 50px);
             margin-left: 1rem;

             @include breakpoint(upto-mobile) {
                 height: max-content;

             }

             .privacy-heading {
                 @include breakpoint(upto-mobile) {
                     background-image: linear-gradient(90deg, #F1AE4C, #F45C43, #d8463d);
                     padding: 1rem;
                     margin-bottom: 2rem;

                     @include breakpoint(upto-mobile-mid) {
                         margin-bottom: 1.2rem;
                     }
                 }

                 h2 {
                     font-size: 2.1em;
                     text-transform: capitalize;
                     color: #000;
                     font-family: $demiBold;

                     @include breakpoint(upto-tablet) {
                         font-size: 1.8em; 
                         @include breakpoint(upto-mobile){
                            margin: 0;
                            font-size: 1.5em;
                            color: #fff;
                         }
                     }
                 }

                 h5,
                 h4 {
                     font-size: 1.0012em;
                     text-transform: capitalize;
                     padding: .5rem 0;
                     font-family: $demiBold;
                     color: #000;
                     font-weight: 500;

                     @include breakpoint(upto-tablet) {
                         font-size: .98em;
                         @include breakpoint(upto-mobile){
                            font-size: .875em;
                            margin: 0;padding-bottom: 0;
                            color: #fff;
                         }
                     }
                 }

                 h4 {
                     border-top: 1px solid #707070;
                     margin-top: 0.7rem;
                     padding: 0.9em 0 0.4rem;
                 }

                 p {
                     font-size: .855em;
                     text-transform: capitalize;
                     font-family: $medium;
                     line-height: 1.9;

                     @include breakpoint(upto-tablet) {
                         font-size: .98em;
                         @include breakpoint(upto-mobile){
                            color: #fff;
                         }
                     }
                 }
             }

             .article-wrapper {
                 padding-bottom: 8rem;
                 border-top: 1px solid #707070;
                 margin-top: .5rem;

                 @include breakpoint(upto-tablet) {
                     padding-bottom: 10rem;

                     @include breakpoint(upto-mobile) {
                         padding-bottom: .5rem;
                         border: none;
                     }
                 }

                 .article-item {
                     @include breakpoint(upto-mobile) {
                         margin-bottom: .8rem;
                         .accordion-button {
                             padding: 0.5rem 1rem; 
                             &:focus {
                                 border-color: transparent;
                                 box-shadow: none;
                             }
                         }
                         @include breakpoint(upto-mobile-mid){
                            margin-bottom: .5rem;
                         }
                     }

                     .article-heading {
                         h3 {
                             font-size: 1.6em;
                             text-transform: capitalize;
                             color: #363636;
                             font-family: $demiBold;
                             padding: .5rem 0;

                             @include breakpoint(upto-tablet) {
                                 font-size: 1.2em;
                                 padding: .5rem 0 0;

                                 @include breakpoint(upto-mobile-mid) {
                                     font-size: 1.089em;
                                     padding: 0rem 0 0;
                                     @include breakpoint(upto-mobile-375){
                                        font-size:  1.039em; 
                                     }
                                 }
                             }
                         }
                     }

                     .article-answer {
                         .article-ans-heading {
                             font-size: 1.0012em;
                             text-transform: capitalize;
                             padding: .5rem 0;
                             font-family: $demiBold;
                             color: #000;
                             font-weight: 500;
                             display: block;

                             @include breakpoint(upto-tablet) {
                                 font-size: .888em;
                             }
                         }

                         .sub-bold {
                             font-size: .855em;
                             font-family: $medium;
                             font-weight: 600;
                         }

                         .article-ans-content {
                             font-size: .855em;
                             text-transform: capitalize;
                             font-family: $medium;
                             line-height: 1.9;

                             .article-sub-ans {
                                 display: block;
                                 padding: .6rem 0;
                             }
                         }

                         .order-list {
                             margin: 1rem 0 0; 

                             li {
                                 font-size: .855em;
                                 text-transform: capitalize;
                                 font-family: $medium;
                                 line-height: 1.6;
                                 list-style-type: disc;
                                 padding: .4rem 0;

                                 p {
                                     font-size: .855em;
                                     text-transform: capitalize;
                                     font-family: $medium;
                                     line-height: 1.6;
                                     list-style-type: disc;
                                     padding: .3rem 0;
                                 }
                             }
                         }

                         .order-list-alpha {
                             margin-left: 1rem;

                             li {
                                 list-style-type: lower-latin;

                                 p {
                                     font-size: .855em;
                                     text-transform: capitalize;
                                     font-family: $medium;
                                     line-height: 1.6;
                                     list-style-type: disc;
                                     padding: .3rem 0;
                                 }
                             }
                         }

                         .table-main-wrapper {
                             table {
                                 border: 1px solid #000;
                                 border-radius: 5px;
                                 margin: 1rem auto;
                                 border-collapse: initial;
                                 border-spacing: 0;
                                 width: 90%;

                                 thead {
                                     tr {
                                         th {
                                             border-bottom: 1px solid #000;
                                             border-right: 1px solid #000;
                                             padding: .4rem;
                                             font-size: .855em;
                                             font-family: $medium;
                                             font-weight: 600;
                                             text-transform: capitalize;
                                             background: #eee;

                                             &:nth-child(2) {
                                                 width: 140px;
                                             }

                                             &:first-child {
                                                 border-top-left-radius: 5px;
                                             }

                                             &:last-child {
                                                 border-right: none;
                                                 border-top-right-radius: 5px;
                                             }
                                         }
                                     }
                                 }

                                 tbody {
                                     tr {
                                         transition: $transition;

                                         &:last-child {
                                             td {
                                                 border-bottom: none;
                                             }
                                         }

                                         td {
                                             border-bottom: 1px solid #000;
                                             border-right: 1px solid #000;
                                             padding: .4rem;
                                             font-size: 0.8125em;
                                             text-transform: capitalize;
                                             font-family: $medium;
                                             line-height: 1.6;

                                             &:last-child {
                                                 border-right: none;
                                             }

                                             ol {
                                                 li {
                                                     list-style-position: inside;
                                                     list-style-type: lower-alpha;
                                                 }
                                             }
                                         }

                                         &:hover {
                                             background: #eee;
                                             transition: $transition;
                                             cursor: pointer;
                                         }

                                         &:nth-child(even) {
                                             td {
                                                 background: #e9544205;
                                             }
                                         }
                                     }
                                 }
                             }
                         }
                     }
                 }
             }
         }
     }
 }

 //  ---<<<<<------Terms-----****----start---->>>>>>----->>
 .terms-wrapper {
     .privacy_page .privacy-grid {
         aside ul {
             position: fixed;
             width: 240px;
             height: calc(100vh - 248px);
             z-index: 80;
             top: 155px;
             overflow-y: auto;
             @include breakpoint(upto-tablet){
                width: 180px;
                height: calc(100vh - 300px);
                top: 100px;
             }

             li {
                 margin-left: 1.6rem;
             }
         }

         .privacy-content-wrapper {
             .article-wrapper {
                 padding-bottom: 6rem;
                 @include breakpoint(upto-mobile){
                    padding-bottom: .5rem;
                 }
                 .terms-ans {
                     .article-ans-heading {
                         display: inline;
                     }

                     .terms-items {
                         margin: .3rem 0;

                         .article-sub-ans {
                             width: max-content;
                         }
                     }
                 }

                 .article-item {
                     .article-answer {
                         .sub-bold {
                             font-size: 1em;
                         }

                         .order-list {
                             margin: 0rem 0 0 1rem;

                             .roman-list-wrap {
                                 li {
                                     list-style-type: lower-roman;
                                     font-size: .955em;
                                 }
                             }
                         }
                     }
                 }
             }
         }

         .privacy-content-wrapper {
             margin-left: 1rem;
         }
     }


 }

 //  ---<<<<<------Terms-----****-----end----->>>>>>----->>