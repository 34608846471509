@import "./variables.scss";

// @font-face {
//   font-family: "AvenirNext-Regular";
//   src: url("../fonts/AvenirNext-Regular-08.ttf") format("truetype"); /* Safari, Android, iOS */
// }
// @font-face {
//   font-family: "AvenirNext-medium";
//   src: url("../fonts/AvenirNext-Medium-06.ttf") format("truetype"); /* Safari, Android, iOS */
// }
// @font-face {
//   font-family: "AvenirNext-bold";
//   src: url("../fonts/AvenirNext-Bold-01.ttf") format("truetype"); /* Safari, Android, iOS */
// }
// @font-face {
//   font-family: "AvenirNext-bold-italic";
//   src: url("../fonts/AvenirNext-BoldItalic-02.ttf") format("truetype"); /* Safari, Android, iOS */
// }
// @font-face {
//   font-family: "AvenirNext-demi-bold";
//   src: url("../fonts/AvenirNext-DemiBold-03.ttf") format("truetype"); /* Safari, Android, iOS */
// }
// @font-face {
//   font-family: "AvenirNext-demi-bold-italic";
//   src: url("../fonts/AvenirNext-DemiBoldItalic-04.ttf") format("truetype"); /* Safari, Android, iOS */
// }
// @font-face {
//   font-family: "AvenirNext-italic";
//   src: url("../fonts/AvenirNext-Italic-05.ttf") format("truetype"); /* Safari, Android, iOS */
// }
// @font-face {
//   font-family: "AvenirNext-ultralight";
//   src: url("../fonts/AvenirNext-UltraLight-11.ttf") format("truetype"); /* Safari, Android, iOS */
// }
// @font-face {
//   font-family: "AvenirNext-ultralight-italic";
//   src: url("../fonts/AvenirNext-UltraLightItalic-12.ttf") format("truetype"); /* Safari, Android, iOS */
// }
// @font-face {
//   font-family: "ProximaNova-Regular";
//   src: url("../fonts/ProximaNovaFont.otf") format("truetype"); /* Safari, Android, iOS */
// }

* {
  box-sizing: border-box;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
html {
  scroll-behavior: smooth;
  body {
    font-family: $reqular;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Avenir-NextMedium";
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 8px;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #f5f5f5;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #777;
  border-radius: 8px;
}

ul,
p {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-bottom: 0 !important;
}

.btn.btn-primary {
  background: $primary 0% 0% no-repeat padding-box;
  border-radius: 25px;
  border: 0;
  height: 40px;
  &:focus {
    box-shadow: none;
  }
}
.bg-primary {
  background: $primary 0% 0% no-repeat padding-box !important;
}
.btn.btn-outline-primary {
  color: $primary;
  border-color: $primary;
  border-radius: 25px;
  height: 40px;
  &:hover {
    background-color: #fff;
  }
}

.btn-check:focus + .btn,
.btn:focus {
  box-shadow: none;
}

.btn.btn-secondary {
  background: #dedede 0% 0% no-repeat padding-box;
  color: #231f20;
  border-radius: 21px;
  border: 0;

  font-family: $medium;
}

#root {
  .chakra-button {
    background: #f45c43 0% 0% no-repeat padding-box;
    border-radius: 9px;
    color: #fff;
    transition: all 0.3s ease-in-out;
    &:hover {
      transition: all 0.3s ease-in-out;
      opacity: 0.88;
    }
  }
}
.modal {
  .chakra-button {
    background: #4284f3 0% 0% no-repeat padding-box;
    border-radius: 9px;
    color: #fff;
  }
}
body.modal-open {
  padding-right: 0 !important;
}

.form-group {
  margin-bottom: 16px;
}
.form-control {
  height: 50px;
  &:focus {
    box-shadow: none;
  }
}

.card {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 12px #00000029;
  border-radius: 20px;
  border: 0;

  .form-label {
    color: #363636;
    font-size: 14px;
    margin-bottom: 12px;
    font-family: $medium;
  }
  .form-control {
    background: #f5f6f8 0% 0% no-repeat padding-box;
    border: 1px solid #cccccc;
    border-radius: 6px;
  }
  .forgot_wrap {
    text-align: center;
    margin-top: 18px;
    a {
      color: $primary;
      font-size: 14px;
      font-weight: bold;
      text-decoration: none;
      font-family: $bold;
    }
  }
  .forgot_wrap.v2 {
    display: flex;
    justify-content: space-between;
    align-content: center;
  }
}

.form-select {
  height: 50px;
}

.ml-35 {
  margin-left: 35px;
}

.PhoneInputCountry {
  display: none;
}

.captcha_wrap {
  margin-bottom: 80px;
  min-height: 80px;

  iframe {
    width: 100%;
  }
  // .rc-anchor-normal {
  //   width: 247px;
  //   .rc-anchor-content {
  //     width: 157px;
  //   }
  // }
}
.captcha_wrap.login {
  margin-bottom: 15px;
}

.error_border {
  .dial_selected {
    .input-group-text {
      border-color: #d8463d;
    }
    .form-control {
      border-color: #d8463d;
    }
  }
}

.player_modal {
  .modal-content {
    border: none;
    width: 100%;
    background-color: none;
    border-radius: 20px;

    .video-react {
      border-top-left-radius: 30px;
      border-bottom-left-radius: 30px;
    }
  }
  .modal-body {
    padding: 0;
  }
}

.login-modal {
  .modal-dialog {
    .modal-content {
      background-color: transparent;
      border: none !important;
      border-radius: 0;
    }
  }
}
.choose-device-modal {
  .modal-dialog {
    width: 356px;
    .modal-content {
      border-radius: 12px;
      height: 274px;
      padding: 2.9em 1.9em 1.9em 1.9em;
      .modal-body {
        padding: 0;
      }
    }
  }
  .close_modal {
    right: 0;
    top: -5px;
  }
}
.btn.btn-default.sm {
  background: #cccccc 0% 0% no-repeat padding-box;
  border-radius: 25px;
  height: 22px;
  font-size: 10px;
  color: #363636;
  display: flex;
  align-content: center;
  padding: 4px 20px;
}
.btn.btn-blue.sm {
  background: #4284f3 0% 0% no-repeat padding-box;
  border-radius: 25px;
  height: 22px;
  font-size: 10px;
  color: #ffffff;
  display: flex;
  align-content: center;
  padding: 4px 20px;
}

.dp_name {
  background: #f45c43;
  display: flex;
  align-content: center;
  justify-content: center;
  border-radius: 50%;
  text-transform: uppercase;
  color: #fff;
}

.user_list_modal {
  .modal-header {
    .modal-title {
      width: 100%;
      text-align: center;
      color: #d8463d;
      font-size: 18px;
      line-height: 25px;
      font-family: $demiBold;
    }
  }
  .modal-content {
    border: none;
    border-radius: 20px;
    position: relative;
    .modal-for-profile {
      border-radius: 30px;
    }
    .suggestions_wrap {
      max-height: 380px;
      overflow-y: auto;
    }
    .user_list_wrap {
      max-height: 100%;
      overflow-y: auto;
      ul {
        border-top: 0;
        margin-top: 0;
        padding-top: 0;
        li {
          border-bottom: 0;
        }
      }
    }
  }

  .close_modal {
    position: absolute;
    top: 5px;
    right: 5px;
    background: #7d7d7d;
    border-radius: 50%;
    font-size: 16px;
    line-height: 25px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
  }
}
.user_list_modal .modal-content .user_list_wrap.follower_following_wrap {
  max-height: 380px;
  overflow-y: auto;
}
.modal_sm {
  .modal-header {
    .modal-title {
      width: 100%;
      text-align: center;
      color: #231f20;
      font-size: 22px;
      line-height: 30px;
      font-family: $medium;
    }
  }
  .modal-content {
    border-radius: 20px;
    position: relative;
  }
}

.modal {
  z-index: 1060;
}

.modal-backdrop {
  z-index: 1060;
}

.user_list_modal {
  .user_list_wrap {
    max-height: 380px;
    overflow-y: auto;
  }
}

.form-check-input {
  &:focus {
    box-shadow: none;
  }
}

.chakra-modal__content {
  border-radius: 24px;
}

.form-check-input:checked[type="radio"] {
  background: #4284f3;
  border-color: #d8d8d8;
  border-width: 2px;
}

@media (min-width: 1900px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1100px;
  }
}

@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1140px;
  }
}
