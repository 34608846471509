@import "./reset.scss";
@import "./variables.scss";
@import "./icons.scss";
@import "./mixin.scss";
@import "./common/breakpoint.scss";
@import "./components/faq.scss";
@import "./components/privacy.scss";
@import "./components/terms.scss";
@import "./components/creator.scss";
@import "./components/homelogin.scss";
// this is a main index file of css
html,
body,
#root {
  height: 100%;

  @include respond(mobile) {
    background: transparent
      linear-gradient(
        180deg,
        #fffaed 0%,
        #fffdf8 39%,
        #fffefc 70%,
        #ffffff 100%
      )
      0% 0% no-repeat padding-box;

    .makeStyles-landingRoot-1.main-feature-wrapper {
      height: 100vh !important;
    }
  }
}
.jobs_wrapper {
  min-height: calc(100vh - 109px)!important;
}
.logo_wrap {
  img {
    height: 40px;

    @include respond(mobile) {
      height: 40px;
    }
  }
}
.feed-modal-wrapper {
  .player_col {
    overflow: inherit !important;
  }
}

// .player_modal,
// .story_modal {
//   &::before {
//     content: "";
//     width: 100%;
//     height: 100%;
//     z-index: -1;
//     background: #000000b5 0% 0% no-repeat padding-box;
//     backdrop-filter: blur(6px);
//     position: absolute;
//     opacity: 1;
//   }
// }

//<<<----all-caught-wrapper----start--here--->>>
.all-caught-wrapper {
  margin: 2rem 0 1rem;
  text-align: center;

  h5 {
    margin: 0 !important;
    font-size: 1em;
    text-transform: capitalize;
    color: #363636;
    font-family: $reqular;
    padding: 0.5rem 0;

    @include breakpoint(upto-mobile) {
      font-size: 0.875em;
    }
  }

  .success-icon {
    display: inline-block;
    width: 2.5em;
    height: 2.5em;
    font-size: $icon-base-size;
    border-radius: 50%;
    border: 3px solid lighten($green, 20%);
    position: relative;
    overflow: hidden;
    transform-origin: center;
    animation: showSuccess 180ms ease-in-out;
    transform: scale(1);

    &__tip,
    &__long {
      display: block;
      position: absolute;
      height: 3px;
      background-color: lighten($green, 20%);
      border-radius: 10px;
    }

    &__tip {
      width: 1em;
      top: 24px;
      left: 0em;
      transform: rotate(45deg);
      animation: tipInPlace 300ms ease-in-out;
      animation-fill-mode: forwards;
      animation-delay: 180ms;
      visibility: hidden;
    }

    &__long {
      width: 4em;
      transform: rotate(-45deg);
      top: 3.7em;
      left: 2.75em;
      animation: longInPlace 140ms ease-in-out;
      animation-fill-mode: forwards;
      visibility: hidden;
      animation-delay: 300ms + 140ms;
    }
  }

  @keyframes showSuccess {
    from {
      transform: scale(0);
    }

    to {
      transform: scale(1);
    }
  }

  @keyframes tipInPlace {
    from {
      width: 0em;
      top: 0em;
      left: -1.6em;
    }

    to {
      width: 15px;
      top: 27px;
      left: 6px;
      visibility: visible;
    }
  }

  @keyframes longInPlace {
    from {
      width: 0em;
      top: 5.1em;
      left: 3.2em;
    }

    to {
      width: 28px;
      top: 23px;
      left: 13px;
      visibility: visible;
    }
  }
}

//<<<----all-caught-wrapper----end--here--->>>

#basic-navbar-nav {
  padding-bottom: 0;
}
.add-tag-wrap {
  overflow-y: hidden;
}

.close_modal {
  position: absolute;
  top: -7px;
  right: -143px;
  color: #fff;
  font-size: 35px;
  cursor: pointer;
  z-index: 99;

  @include breakpoint(upto-landscape-tablet) {
    right: -80px;

    @include breakpoint(upto-landscape-tablet-992) {
      right: -27px;

      @include breakpoint(upto-tablet) {
        right: -25px !important;

        i.icon.cross {
          width: 20px;
          height: 20px;
        }

        @include breakpoint(upto-mobile-mid) {
          right: 13px !important;
          top: 1px;

          .icon {
            width: 18px;
            height: 17px;
          }
        }
      }
    }
  }

  @include respond(tablet) {
    position: absolute;
    z-index: 1;
    padding: 15px 15px;
    top: 10px;
    right: 10px;
    color: #fff;
    font-size: 25px;
  }
}

.isExpanded {
  .people_you_may_know_card {
    display: none;
  }
}

.navbar {
  justify-content: space-between;

  // width: 100%;
  @include respond(mobile) {
    padding-bottom: 9px;
    justify-content: space-between;
    width: auto;
    flex-wrap: nowrap;
  }
}

.left_nav {
  width: 67%;
  display: flex;
  justify-content: flex-end;

  @include respond(mobile) {
    flex-wrap: wrap-reverse;
  }

  @include respond(tablet) {
    flex-wrap: wrap-reverse;
  }

  @include respond(smScreen) {
    width: 67%;
  }

  @include respond(midScreen) {
    width: 67%;
  }
}

body {
  .chakra-modal__content {
    border-radius: 30px;
  }
}

.navbar-light .navbar-nav {
  align-items: center;

  .nav-link {
    color: rgba(0, 0, 0, 0.55);
    height: 28px;
    display: flex;
    align-items: center;
    // padding: 0 0 0 22px;
    // &:first-child {
    //   border-right: 2px solid #707070;
    //   padding-right: 22px;
    // }
  }

  .notification_link {
    margin-right: 10px;
  }

  .username_link {
    font-family: $reqular;
    font-weight: bold;
    font-size: 15px;
    padding-left: 10px;
    color: #231f20;

    @include respond(mobile) {
      display: none;
    }
  }

  .img_link.nav-link {
    border-left: 2px solid #707070;

    img {
      margin-left: 5px;
    }

    .dp_name {
      height: 30px;
      width: 30px;
      min-width: 30px;
      border-radius: 50%;
      line-height: 30px;
    }
  }
}

.early_access {
  background: transparent
    linear-gradient(180deg, #fffaed 0%, #fffdf8 39%, #fffefc 70%, #ffffff 100%)
    0% 0% no-repeat padding-box;
  height: 100%;

  .wrap_box {
    background-image: url(../img/early_bg.png);
    background-size: cover;
    background-position: 0% 38%;
  }

  .logo_wrap {
    position: absolute;
    left: 80px;
    top: 20px;
  }

  .head_box {
    .heading {
      letter-spacing: 0px;
      color: #231f20;
      font-size: 28px;
      line-height: 35px;
      margin-bottom: 0;
      font-family: $demiBold;
    }

    .sub_heading {
      letter-spacing: 0px;
      color: #231f20;
      font-size: 17px;
      line-height: 60px;
      font-family: $demiBold;
    }
  }
}

.hamburger {
  display: none;

  @include respond(smScreen4) {
    display: block;
  }
}

@include respond(smScreen4) {
  .hamburger_nav {
    top: 4.5rem;
    position: fixed;
    background: #fffaee;
    width: 100%;
    left: 0;
    height: 100vh;

    .flex-column.nav {
      margin-top: 1rem;

      .nav-link {
        padding: 0.5rem 1rem !important;
        font-size: 1em !important;
        line-height: 1.3 !important;
        margin: 0 0 0.8rem;

        .icon {
          margin-right: 0.5rem;
          width: 28px !important;
          height: 28px !important;
        }
      }
    }
  }
}

.hamburger_nav {
  @include respond(smScreen4) {
    .nav-link {
      color: #231f20;
      font-weight: bold;
      font-family: $medium;
      display: flex;
      align-items: center;
    }

    h5 {
      font-size: 16px;
      line-height: 33px;
      color: #3e4347;
      font-family: $reqular;
    }
  }
}

.login_share_post_modal {
  display: flex !important;
  justify-content: center;

  .modal-dialog {
    max-width: 90%;

    .modal-content {
      border: none;
      min-height: 271px;
      border-radius: 1.3rem;

      .modal-body {
        display: flex;
        justify-content: center;
      }

      .share_post_wraper {
        text-align: center;
        align-items: center;
        display: flex;
        justify-content: space-evenly;
        flex-direction: column;

        .icon-md {
          &.comment_share {
            height: 41px;
            width: 41px;
          }

          &.follow_share {
            width: 87px;
            height: 30px;
          }
        }

        .bi {
          font-size: 30px;
          position: absolute;
          top: 5px;
          right: 10px;
          cursor: pointer;
        }

        .login_text {
          font-weight: bold;
          font-size: 20px;

          @include breakpoint(upto-mobile-mid) {
            font-size: 1em;

            @include breakpoint(upto-mobile-375) {
              font-size: 0.89em;
            }
          }
        }

        .download_image {
          width: 20rem;
          display: flex;
          color: #707070;
          justify-content: space-evenly;
        }
      }
    }
  }
}

.broken_page {
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .broken_wraper {
    height: 8rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;

    .p_1 {
      font-size: 20px;
      font-weight: bold;
    }

    .p_2 {
      font-size: 15px;
    }

    .download_image {
      width: 20rem;
      display: flex;
      color: #707070;
      justify-content: space-evenly;
    }
  }
}

.share_post_login {
  .modal-dialog {
    max-width: 380px;

    .modal-content {
      border: none;
      min-height: 293px;
      border-radius: 1.3rem;

      .modal-body {
        display: flex;
        justify-content: center;
      }

      .share_post_wraper {
        text-align: center;
        align-items: center;
        display: flex;
        justify-content: space-evenly;
        flex-direction: column;

        .bi {
          cursor: pointer;
        }

        .download_image {
          width: 20rem;
          display: flex;
          color: #707070;
          justify-content: space-evenly;
        }
      }
    }
  }
}

.parent-beta-wrapper {
  @include breakpoint(upto-mobile-mid) {
    .public_footer {
      position: static !important;
    }
  }
}

.choose_device_name {
  .choose_section {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    div {
      text-align: center;

      p {
        &:first-child {
          font: normal normal 18px/25px $demiBold;
        }

        &:last-child {
          color: #231f20;
          margin-top: 0.8em;
          font: normal normal 14px/19px $medium;
        }
      }
    }

    .chakra-input {
      margin-top: 1.4em;
      background: transparent;
      width: 278px;
      height: 36px;
      border: 1px solid #707070;
      border-radius: 25px;
      font: normal normal 14px/19px $medium;
      color: #707070;
    }
    .chakra-button {
      width: 278px;
      height: 36px;
      background: #d8463d 0% 0% no-repeat padding-box;
      border-radius: 25px;
      font: normal normal 14px/19px $demiBold;
      letter-spacing: 0px;
      color: #ffffff;
    }
  }
}

.main_download {
  background-image: url(../img/early_bg_beta.png);
  background-size: cover;
  background-position: 0% 67%;

  .social-wrapper {
    position: fixed;
    left: 0;
    top: 30%;
    background: transparent;
    border-radius: 0.4rem;

    @include breakpoint(upto-mobile-mid) {
      position: static;
      left: initial;
      top: initial;
      display: grid;
      grid-template-columns: max-content 1fr;
      grid-gap: 1rem;
      align-items: center;
    }

    .socail-unorder {
      display: block !important;
      grid-gap: 1rem 0;

      @include breakpoint(upto-mobile-mid) {
        display: flex !important;
        margin: 0 !important;
        grid-gap: 0.5rem;
        width: 100% !important;
        margin: 0.5rem 0 0;

        li {
          margin: 0.3rem 0 0 !important;

          button,
          #popover-trigger-2 {
            width: 32px !important;
            height: 32px !important;
          }
        }
      }

      li {
        margin: 0 0 0.3rem;

        button {
          cursor: pointer;
          transition: $transition;

          &:hover {
            opacity: 0.78;
            transition: $transition;
          }
        }
      }
    }
  }

  @include respond(smScreen3) {
    background-image: none;
    background: transparent
      linear-gradient(
        180deg,
        #fffaed 0%,
        #fffdf8 39%,
        #fffefc 70%,
        #ffffff 100%
      )
      0% 0% no-repeat padding-box;
  }

  .download_container {
    display: flex;
    justify-content: center;
    padding: 0;

    .download_box {
      // margin-top: -50px;
      display: flex;
      min-height: calc(100ch - 424px);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-weight: bold;

      .trailer_video {
        border-radius: 12px;
        width: 500px;
        height: 300px;
        margin: 36px 0px 10px;

        @include respond(smScreen3) {
          width: 370px;
          height: 225px;
          margin: 5px 0px;

          @include respond(smMobile) {
            width: 310px;
            height: 215px;
            margin: 5px 0px;

            @include breakpoint(upto-mobile-320) {
              width: 290px;
            }
          }
        }
      }

      .download_cong_text {
        font-size: 30px;
        line-height: 45px;

        @include respond(mobile) {
          font-size: 22px;
        }

        @include respond(smMobile) {
          font-size: 22px;
        }
      }

      .beta_cong_text {
        font-size: 30px;
        line-height: 45px;

        @include respond(mobile) {
          font-size: 1.2em;
          line-height: 1.4;
          margin: 0.4rem 0;

          @include respond(smMobile) {
            font-size: 1em;
          }
        }
      }

      .download_ios_and {
        margin-top: 34px;
        font-size: 20px;

        @include respond(mobile) {
          font-size: 16px;
        }
      }

      .download_buttons {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 26px;

        @include respond(mobile) {
          justify-content: center;
          flex-direction: column;
          align-items: center;
        }

        .download_button {
          box-shadow: 0px 3px 6px -4px;
          margin-bottom: 22px;
        }
      }

      .beta_buttons {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 26px;

        @include respond(mobile) {
          justify-content: center;
          flex-direction: column;
          align-items: center;
          margin-top: 0.5rem;
        }

        .download_button {
          box-shadow: 0px 3px 6px -4px;
          margin-bottom: 22px;
        }
      }
    }
  }
}

.logoutButton {
  .modal-header {
    button {
      display: none;
    }

    .modal-title {
      font-weight: bold;
      font-size: 1.5em;
      font-family: $medium;
      text-align: center;
      width: 100%;

      @include breakpoint(upto-mobile-375) {
        font-size: 1.1em;
      }
    }
  }

  .modal-body {
    font-weight: 500;
    text-transform: capitalize;
    font-size: 1em;
    font-family: $reqular;
    text-align: center;

    @include breakpoint(upto-mobile-mid) {
      font-size: 0.875em;
    }
  }
}

.login_wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-image: url(../img/login_bg.png);
  background-size: cover;
  background-position: 0% 67%;
  object-fit: contain;

  .logo_wrap {
    position: absolute;
    left: 80px;
    top: 20px;

    img {
      height: 40px;
    }
  }

  .login_button_wraper {
    button {
      a {
        text-decoration: none;
        transition: $transition;

        &:hover {
          color: #fff !important;
          opacity: 0.89;
          transition: $transition;
        }
      }
    }

    @include breakpoint(upto-mobile) {
      display: flex;
      flex-direction: column-reverse;
      flex-wrap: wrap;

      .login_box_wrap {
        width: 90%;
        margin: 0 auto;

        .card {
          width: 100% !important;

          h5 {
            font-size: 23px;
            margin-bottom: 11px;
          }

          form {
            .form-control {
              height: 36px;
            }

            .icon {
              top: 61% !important;
              width: 18px;
              height: 18px;
            }
          }
        }
      }
    }

    .like_influ {
      position: absolute;
      bottom: 9rem;
      left: 6rem;
      border: none;
      border-radius: 1.5rem !important;
      box-shadow: none;
      background: transparent
        linear-gradient(254deg, #d8463d 0%, #f45c43 47%, #f3af41 100%) 0% 0%
        no-repeat padding-box !important;
      font-weight: 500;
      padding: 0.9rem;

      @include breakpoint(upto-mobile) {
        position: relative;
        left: inherit;
        bottom: inherit;
        width: 80%;
        margin: 2rem auto;
      }

      a {
        display: grid;
        width: 100%;
        grid-template-columns: max-content 1fr;
        align-items: center;
        grid-gap: 0.8rem;
      }
    }
  }

  .wrap {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    flex-direction: column;
    flex: 1;
    padding-right: 215px;
    padding-top: 50px;

    @include respond(bigScreen) {
      padding-top: 60px;
      height: calc(100vh - 141px);
    }

    @include respond(midScreen) {
      height: calc(100vh - 141px);
      padding-top: 60px;
    }

    @include respond(mobile) {
      height: calc(100vh - 374px) !important;
    }

    .chakra-button {
      box-shadow: none;
      text-transform: uppercase;
      background: #4284f3 0 0 no-repeat padding-box;
      border-radius: 25px;
      border: 0;
      height: 40px;
      color: #fff;
      width: 100%;
      font-size: 12px;
      font-family: $demiBold;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      line-height: 0;
    }

    .or_text {
      text-align: center;
      color: #231f20;
      font-size: 18px;
      line-height: 25px;
      font-family: $medium;
      margin-top: 11px;
      margin-bottom: 5px;
    }

    .forgot_wrap {
      .form-check-label {
        font-family: $medium;
        font-size: 14px;
        line-height: 16px;
      }

      a {
        font-family: $demiBold;
      }
    }
  }

  .card {
    width: 345px;
    height: auto;
    padding: 53px 19px 19px;

    @include respond(smScreen) {
      width: 340px;
      height: auto;
      padding: 20px 19px 19px;
    }

    h5 {
      font-size: 30px;
      margin-bottom: 40px;
      font-weight: bold;
      position: relative;
      font-family: $bold;

      &:before {
        content: "";
        position: absolute;
        left: -19px;
        height: 70px;
        width: 2px;
        background-color: $primary;
        top: -32px;

        @include respond(smScreen) {
          top: -5px;
        }
      }
    }
  }

  .not_account_wrap {
    text-align: center;
    padding-top: 28px;
    width: 360px;

    a {
      color: #363636;
      font-size: 16px;
      text-decoration: none;
      font-family: $medium;

      strong {
        position: relative;

        &::before {
          content: "";
          position: absolute;
          bottom: -5px;
          width: 100%;
          height: 2px;
          background: #d8463d;
        }
      }
    }
  }

  .app_get_wrap {
    text-align: center;
    padding-top: 20px;

    width: 360px;
    display: flex;
    justify-content: center;

    @include respond(bigScreen) {
      padding-top: 40px;
    }

    @include respond(midScreen) {
      padding-top: 15px;
    }

    @include respond(smScreen) {
      width: 300px;
    }

    .download_app {
      display: flex;

      img {
        height: 35px;

        @include respond(smScreen) {
          height: 28px;
        }
      }
    }

    h5 {
      color: #363636;
      font-size: 16px;
      text-decoration: none;
      font-family: $medium;
    }
  }
}

.bottom_nav {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding-bottom: 20px;

  .nav {
    width: 100%;
    justify-content: center;
    margin-bottom: 15px;

    .nav-link {
      color: #231f20;
      font-size: 14px;
      font-weight: medium;
      font-family: $medium;
    }
  }

  h5 {
    text-align: center;
    font-size: 14px;
    color: #363636;
    font-weight: medium;
    font-family: $medium;
  }
}

.signup {
  .bottom_nav {
    position: relative;
  }
}

.signup_wrapper {
  background: transparent
    linear-gradient(180deg, #fffaed 0%, #fffdf8 39%, #fffefc 70%, #ffffff 100%)
    0% 0% no-repeat padding-box;
  height: 100%;
  padding: 55px 70px 0;

  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;

    a {
      color: #363636;
      font-size: 16px;
      text-decoration: none;
    }
  }

  .body {
    padding-top: 70px;

    .card {
      .form-control {
        background: #ffffff 0% 0% no-repeat padding-box;
      }

      .form-group {
        ul {
          li {
            width: auto;
          }
        }

        .number_group {
          .input-group-text {
            background-color: transparent;
            border-right: 0;
          }

          .form-control {
            border-left: 0;
          }
        }

        .dial_selected {
          .input-group-text {
            position: relative;

            &:after {
              content: " ";
              position: absolute;
              right: 0;
              width: 4px;
              top: 12px;
              bottom: 12px;
              background-color: #d8463d;
            }
          }
        }
      }
    }

    ul.step_ul {
      display: flex;
      justify-content: space-between;

      li {
        width: 300px;

        .nav_link {
          color: #363636;
          font-size: 18px;
          font-family: $bold;
          text-align: center;
          border-bottom: 1px solid #707070;
          padding-bottom: 16px;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;

          &:before {
            content: " ";
            border: 1px solid #f25d49;
            width: 18px;
            height: 18px;
            border-radius: 50%;
            position: relative;
            left: -14px;
            display: inline-block;
          }
        }

        .contact {
          font-family: $reqular;

          strong {
            font-family: $bold;
            margin-right: 5px;
          }
        }
      }

      .active {
        .nav_link {
          position: relative;

          &:before {
            content: " ";
            border: 1px solid #f25d49;
            width: 18px;
            height: 18px;
            border-radius: 50%;
            position: relative;
            left: -14px;
            display: inline-block;
            background-image: url(../img/icons/tick.svg);
            background-size: contain;
          }

          &:after {
            content: " ";
            position: absolute;
            bottom: -5px;
            height: 6px;
            width: 100%;
            background: $primary;
            left: 0;
            border-radius: 10px;
          }
        }
      }

      .selected {
        .nav_link {
          position: relative;

          &:before {
            content: " ";
            border: 1px solid #f25d49;
            width: 18px;
            height: 18px;
            border-radius: 50%;
            position: relative;
            left: -14px;
            display: inline-block;
            background-image: url(../img/icons/tick.svg);
            background-size: contain;
          }
        }
      }

      .step_one {
        .card {
          background-image: url(../img/icons/c.svg);
          background-position: 55% 25%;
          background-repeat: no-repeat;
        }
      }

      .step_one.selected {
        background-image: url(../img/icons/c.svg);
        background-position: center;
        background-repeat: no-repeat;
      }

      .step_two {
        .card {
          background-image: url(../img/icons/a.svg);
          background-position: 55% 25%;
          background-repeat: no-repeat;
        }
      }

      .step_two.selected {
        background-image: url(../img/icons/a.svg);
        background-position: center;
        background-repeat: no-repeat;
      }

      .step_three {
        .card {
          background-image: url(../img/icons/c.svg);
          background-position: 55% 25%;
          background-repeat: no-repeat;
        }
      }

      .step_three.selected {
        background-image: url(../img/icons/c.svg);
        background-position: center;
        background-repeat: no-repeat;
      }

      .step_four {
        .card {
          background-image: url(../img/icons/h.svg);
          background-position: 55% 25%;
          background-repeat: no-repeat;
        }
      }

      .step_four.selected {
        background-image: url(../img/icons/h.svg);
        background-position: center;
        background-repeat: no-repeat;
      }

      .step_five {
        .card {
          background-image: url(../img/icons/y.svg);
          background-position: 55% 25%;
          background-repeat: no-repeat;
        }
      }

      .step_five.selected {
        background-image: url(../img/icons/y.svg);
        background-position: center;
        background-repeat: no-repeat;
      }
    }

    .nav-tabs {
      justify-content: space-between;

      .nav-item {
        .nav-link {
          color: #363636;
          font-size: 18px;
          font-family: $bold;
          outline: none;

          &:focus,
          &:hover {
            border-color: transparent;
          }
        }

        .active {
          background-color: transparent;
          border: 0;
          position: relative;

          &:after {
            content: " ";
            position: absolute;
            bottom: -5px;
            height: 6px;
            width: 100%;
            background: $primary;
            left: 0;
            border-radius: 10px;
          }
        }
      }
    }

    .content_body {
      padding-top: 31px;

      .card {
        width: 300px;
        height: auto;
        padding: 26px;

        .PhoneInputInput {
          height: 50px;
          background: #f5f6f8 0% 0% no-repeat padding-box;
          border: 1px solid #cccccc;
          border-radius: 6px;
          width: 100%;
          background-color: transparent;
          padding-left: 13px;
        }

        h5 {
          margin-bottom: 40px;
          margin-top: 30px;
          text-align: center;
          font-size: 23px;

          strong {
            font-family: $bold;
          }
        }

        small {
          color: #363636;
          font-size: 10px;

          line-height: 14px;
          display: inline-block;
          margin: 0 0 32px 0;
        }
      }

      .auth {
        h5 {
          font-size: 23px;
          font-family: $bold;
          text-align: center;
        }

        .form-label {
          font-size: 12px;
        }

        .form-control {
          height: 50px;
        }

        p {
          font-size: 12px;
          font-family: $medium;
          text-align: center;
          margin-bottom: 30px;
        }

        h6 {
          font-size: 14px;
          font-family: $medium;
          text-align: center;
          margin-bottom: 20px;

          span {
            color: $primary;
          }
        }

        .btn {
          width: 100%;
        }

        .btn-outline-primary {
          margin-top: 20px;
        }
      }

      .your_name {
        .info_text {
          font-size: 10px;
          color: #363636;
          font-family: $medium;
          text-align: center;
          margin-bottom: 24px;
        }
      }

      .birthday {
        position: relative;

        &:before {
          content: " ";
          position: absolute;
          top: 15px;
          right: -15px;
          background-image: url(../img/birthday.svg);
          height: 150px;
          width: 113px;
        }

        .form-label {
          font-size: 12px;
          text-align: center;
          display: inline-block;
          width: 100%;
          margin-bottom: 25px;
        }

        .info_text {
          font-size: 12px;
          color: #363636;
          font-family: $medium;
          text-align: center;
          margin-top: 30px;
          margin-bottom: 60px;
        }

        .form-select {
          font-size: 14px;
          color: #cccccc;
          padding: 0 6px;
          height: 40px;
        }

        .select_row {
          display: flex;
          justify-content: space-between;

          .month {
            width: 109px;

            .form-select {
              padding-left: 13px;
            }
          }

          .day {
            width: 52px;
          }

          .year {
            width: 75px;
          }
        }

        .btn.btn-outline-primary {
          margin-top: 20px;
        }
      }

      .dp {
        h5 {
          margin-bottom: 70px;
        }

        .dp_wrap {
          text-align: center;

          position: relative;
          height: 130px;
          width: 130px;
          margin: 0 auto 80px;
          border-radius: 50%;

          .add_dp {
            background: #ffffff 0% 0% no-repeat padding-box;
            box-shadow: 0px 6px 10px #00000029;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            position: absolute;
            bottom: 0;
            right: 0;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;

            .bi {
              display: flex;
              align-items: center;
              color: #d8463d;
              font-size: 26px;
              font-family: $bold;
            }
          }
        }

        .info_text {
          color: #363636;
          font-size: 12px;
          font-family: $medium;
          text-align: center;
          margin-bottom: 29px;
        }
      }
    }
  }
}

.bottom_auth {
  text-align: center;
  margin-top: 29px;
  margin-bottom: 29px;

  h5 {
    margin-bottom: 15px;
    color: #363636;
    font-size: 16px;
    font-family: $medium;
  }
}

.about_wrapper {
  background: transparent linear-gradient(180deg, #fffaed 0%, #ffffff 100%) 0%
    0% no-repeat padding-box;

  .logo_wrap {
    position: absolute;
    left: 80px;
    top: 20px;

    @include respond(mobile) {
      left: 24px;
      top: 29px;
    }

    img {
      height: 40px;
    }
  }

  .publick_navbar {
    @include respond(mobile) {
      padding-top: 0;
    }

    .navbar {
      @include respond(mobile) {
        padding-bottom: 7px;
      }
    }
  }

  .head {
    padding: 50px 80px 0px;
    border-bottom: 1px solid #ccc;
    background: #fffaed;

    @include respond(mobile) {
      border-bottom: 0;
    }

    .nav {
      justify-content: center;
      margin-top: 20px;

      @include respond(mobile) {
        display: none;
      }

      .nav-item {
        .nav-link {
          color: #363636;
          font-size: 22px;
          font-family: $demiBold;
          padding: 0 60px;
          cursor: pointer;
          height: 56px;
        }

        .active {
          background-color: transparent;
          border: 0;
          position: relative;

          &:after {
            content: " ";
            position: absolute;
            bottom: -3.5px;
            height: 6px;
            width: 100%;
            background: $primary;
            left: 0;
            border-radius: 10px;
          }
        }
      }
    }
  }

  .body {
    section {
      padding: 160px 0;

      @include respond(mobile) {
        padding: 25px 0;
      }

      .content {
        height: 100%;
        display: flex;
        align-items: center;
        padding-left: 60px;

        @include respond(mobile) {
          padding-left: 15px;
          padding-bottom: 30px;
        }

        h2 {
          color: #363636;
          font-size: 50px;
          font-family: $demiBold;
          margin-bottom: 40px;

          @include respond(mobile) {
            font-size: 22px;
            margin-bottom: 15px;
          }
        }

        p {
          color: #363636;
          font-size: 24px;
          line-height: 40px;
          font-family: $medium;

          @include respond(mobile) {
            font-size: 14px;
            line-height: 28px;
          }
        }
      }
    }

    .feeds {
      position: relative;

      &:after {
        content: "";
        position: absolute;
        bottom: -50px;
        width: 100%;
        background-image: url("../img/gray_layer_feature.svg");
        height: 358px;
        background-size: contain;
        background-repeat: no-repeat;

        @include respond(smScreen) {
          height: 300px;
        }

        @include respond(mobile) {
          bottom: -36px;
          height: 104px;
        }
      }

      .col-md-4 {
        img {
          height: 470px;

          @include respond(mobile) {
            height: 215px;
            margin: 0 auto;
          }
        }
      }
    }

    .get_cachy {
      background: #f5f6f8 0% 0% no-repeat padding-box;
      position: relative;

      &:after {
        content: "";
        position: absolute;
        bottom: -50px;
        width: 100%;
        background-image: url("../img/white_layer.svg");
        height: 220px;
        background-size: contain;
        background-repeat: no-repeat;

        @include respond(mobile) {
          height: 104px;
          bottom: -40px;
        }

        @include respond(smMobile) {
          height: 104px;
          bottom: -45px;
        }

        @include respond(smScreen) {
          height: 230px;
          bottom: -28px;
        }
      }

      .content {
        padding-left: 0;
        padding-right: 100px;
      }

      .col-md-4 {
        .right_img {
          height: auto;
        }

        img {
          height: 470px;

          @include respond(mobile) {
            height: 215px;
            margin: 0 auto;
          }
        }
      }
    }

    .sw-mobile {
      @include respond(mobile) {
        order: 2;
        margin-top: 20px;
      }
    }

    .cachy_select {
      background: #fff;
      position: relative;

      &:after {
        content: "";
        position: absolute;
        bottom: -50px;
        width: 100%;
        background-image: url("../img/gray_layer_feature.svg");
        height: 358px;
        background-size: contain;
        background-repeat: no-repeat;

        @include respond(mobile) {
          bottom: -36px;
          height: 104px;
        }

        @include respond(smMobile) {
          height: 104px;
          bottom: -37px;
        }

        @include respond(smScreen) {
          height: 300px;
        }
      }

      .col-md-4 {
        img {
          height: 370px;

          @include respond(mobile) {
            height: 215px;
            margin: 0 auto 20px;
          }
        }
      }
    }

    .messanger {
      background: #f5f6f8 0% 0% no-repeat padding-box;
      position: relative;

      &:after {
        content: "";
        position: absolute;
        bottom: -50px;
        width: 100%;
        background-image: url("../img/white_layer.svg");
        height: 358px;
        background-size: contain;
        background-repeat: no-repeat;

        @include respond(mobile) {
          height: 104px;
          bottom: -40px;
        }

        @include respond(smMobile) {
          height: 104px;
          bottom: -50px;
        }

        @include respond(smScreen) {
          height: 270px;
        }
      }

      padding-bottom: 200px;

      @include respond(mobile) {
        padding-bottom: 20px;
      }

      .content {
        padding-left: 0;
        padding-right: 100px;
      }

      .col-md-4 {
        img {
          height: 370px;

          @include respond(mobile) {
            height: 215px;
            margin: 20px auto 0;
          }
        }
      }
    }
  }
}

footer {
  .nav {
    justify-content: space-around;
    padding-top: 10px;
    align-items: center;

    .nav-item {
      .nav-link {
        color: #231f20;
        font-size: 14px;
        font-family: $medium;

        select {
          height: 25px;
          display: flex;
          align-items: center;
          padding: 0 30px 0 7px;
          width: 120px;
        }
      }
    }
  }
}

.wrapper {
  height: 100%;
  background: transparent
    linear-gradient(180deg, #fffaed 0%, #fffdf8 39%, #fffefc 70%, #ffffff 100%)
    0% 0% no-repeat padding-box;

  .app_nav {
    margin: 0 auto;
    z-index: 1051;

    @include respond(mobile) {
      width: 100%;
      // border-bottom: 1px solid #e4e4e4;
      background: #fffaed;
      margin-right: 0 !important;
    }
  }

  .main_body {
    max-height: calc(100vh - 84px);
    overflow-y: auto;
    height: 100%;

    .profile_wrap {
      padding: 0px 10px;
      border-radius: 12px 12px 0 0;
      margin-bottom: 10px;
      height: 100%;

      @include respond(mobile) {
        padding: 0px;
      }

      .profile_body {
        background: #ffffff 0% 0% no-repeat padding-box;

        .tab-content {
          height: 93%;
          overflow: auto;
        }

        ul {
          li {
            button {
              padding: 5px;
            }
          }
        }
      }

      .bio_box {
        min-height: 40px;
      }

      .user_bio {
        max-height: 40vh;

        @include breakpoint(upto-Semi-XL-wide-screen) {
          max-height: 33vh;

          @include breakpoint(upto-tablet-800) {
            max-height: 40vh;
          }
        }

        .no_info_wrap {
          .user_has {
            color: #707070;
            font-size: 18px;
            line-height: 25px;
          }
        }

        .inner_bio {
          color: #231f20;
          font: normal normal medium 16px/28px $medium;
        }

        p {
          // height: 145px;
          white-space: break-spaces;
          line-height: 2 !important;
          font-size: 1em;

          @include breakpoint(upto-landscape-tablet) {
            // height: 70px;

            @include breakpoint(upto-tablet) {
              // height: 130px;

              @include breakpoint(upto-mobile) {
                font-size: 0.875em;
                // height: 125px;

                @include breakpoint(upto-mobile-mid) {
                  // height: 110px;
                }
              }
            }
          }
        }
      }
    }

    @include respond(mobile) {
      max-height: 100%;
    }
  }

  .feed_row {
    width: 100%;
    display: flex;
    margin-top: 0;

    .right_footer {
      position: fixed;
      bottom: 0.7rem;
      width: 18.5rem;
      background: #fff;

      ul li {
        position: relative;

        &:hover {
          transition: $transition;
          opacity: 0.89;

          &::before {
            content: "";
            width: 100%;
            height: 2px;
            background: #000;
            position: absolute;
            left: 0;
            bottom: 0;
            animation: borderAnime 0.4s linear;
            transition: $transition;
          }
        }

        @keyframes borderAnime {
          0% {
            width: 0;
            transition: $transition;
          }

          100% {
            width: 100%;
            transition: $transition;
          }
        }
      }
    }

    @include respond(mobile) {
      padding: 0;
    }

    .feed_col_left {
      height: calc(100vh - 84px);
      max-width: 273px;
      min-width: 300px;
      display: flex;
      padding: 10px;
      overflow-y: scroll;
      justify-content: space-between;
      flex-direction: column;
      top: 0;

      @include respond(mobile) {
        display: none;
      }

      @include respond(smScreen3) {
        display: none;
      }
    }

    .feed_col_left::-webkit-scrollbar {
      display: none;
    }

    .is_shadow {
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000029;
      padding: 30px 0;
      border-radius: 14px 14px 0 0;

      .vertical_nav_container {
        height: max-content !important;

        &.nav {
          display: inherit;
        }
      }

      .right_footer {
        padding: 0 0.9rem;
      }
    }

    .feed_col_middle {
      width: 68%;
      flex: 1;
      margin-left: 1.5rem;

      @include respond(mobile) {
        width: 100%;
        margin-left: 0;
      }

      @include respond(smScreen3) {
        width: 100%;
        margin-left: 0;
      }
    }

    .feed_col_right {
      top: 0;
      width: 25%;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 12px;
      height: calc(100vh - 115px);
      padding: 15px 9px 15px 15px;
      display: flex;
      flex-direction: column;

      @include respond(mobile) {
        display: none;
      }

      .notification_wrap {
        flex: 1;
      }
    }
  }
}

.image_placeholder {
  background: url(../img/image_placeholder.png) no-repeat;
}

.right_footer {
  // margin-top: 44px;
  ul {
    display: flex;
    justify-content: space-between;

    li {
      a {
        color: black;
        font-size: 10px;
        font-family: $reqular;
        font-weight: bold;
      }
    }
  }

  p {
    font-size: 10px;
    font-weight: bold;
    font-family: $reqular;
  }

  img {
    height: 20px;
  }
}

.feed_profile_card {
  padding-top: 20px;

  .profile_head {
    text-align: center;

    .img_wrap {
      img {
        height: 90px;
        width: 90px;
        border-radius: 50%;
      }

      .dp_name {
        height: 90px;
        min-width: 90px;
        width: 90px;
        background: #f45c43;
        display: flex;
        align-content: center;
        justify-content: center;
        border-radius: 50%;
        font-size: 35px;
        margin: 0 auto;
        line-height: 90px;
      }

      margin-bottom: 27px;

      @include respond(smScreen) {
        margin-bottom: 15px;
      }
    }

    h5 {
      color: #363636;
      font-size: 22px;
      font-family: $bold;
      line-height: 35px;
      margin-bottom: 0;
      text-align: left;
      display: flex;
      align-items: flex-start;

      .icon {
        margin-left: 6px;
        height: 16px;
        width: 16px;
        margin-top: 6px;
      }
    }

    p {
      color: #707070;
      font-size: 18px;
      font-family: $medium;
      line-height: 20px;
      margin-bottom: 0;
      text-transform: capitalize;
    }

    .username {
      text-align: left;
    }

    ul {
      display: flex;
      justify-content: space-around;
      width: 100%;

      li {
        position: relative;
        cursor: pointer;
        width: 33.333%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        &:last-child {
          border-right: none;
        }

        strong {
          color: #363636;
          font-size: 12px;
          font-family: $demiBold;
          line-height: 26px;
        }

        p {
          color: #707070;
          font-size: 12px;
          font-family: $medium;
          line-height: 16px;
          margin-bottom: 0;
        }
      }
    }
  }

  .profile_body {
    word-break: break-word;
    overflow: hidden;
    min-height: 100px;

    ul.tags {
      display: flex;
      align-items: center;
      margin-top: 18px;
      margin-bottom: 5px;

      li {
        border-right: 1px solid #363636;
        padding: 0 5px;
        height: 11px;
        display: flex;
        align-items: center;
        text-align: left;

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          border-right: none;
        }

        color: #363636;
        font-size: 14px;
        font-family: $medium;
      }
    }

    .bio_text {
      margin-top: 15px;

      p {
        color: #363636;
        font-size: 14px;
        line-height: 21px;
        font-family: $medium;
        margin: 0;
        text-transform: capitalize;
      }
    }

    .show_more_cta {
      text-align: right;
      color: #d8463d;
      font-family: $demiBold;
      font-size: 12px;
      cursor: pointer;
    }
  }
}

.mention {
  padding: 4px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 8px;
  width: 226px;
  position: absolute;
  bottom: 72px;
  background-color: white;

  p {
    margin-bottom: 0 !important;
  }

  .mentionInner {
    max-height: 236px;
    overflow-y: scroll;
  }

  .mentionInner::-webkit-scrollbar {
    display: none;
  }
}

.people_you_may_know_card {
  width: 100%;
  overflow-y: auto;
  margin-bottom: 20px;

  .button_show_more {
    width: 91px;
    color: #d8463d;
    font-size: 12px;
    font-weight: bold;
    background: none !important;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 1.8rem;
  }

  .user_list_wrap ul {
    border: 0;
    margin: 0;
    padding: 0;
  }

  .user_list_wrap::-webkit-scrollbar {
    display: none;
  }

  h5 {
    color: #d8463d;
    font-size: 14px;
    font-family: $reqular;
    font-weight: bold;
    line-height: 19px;
    margin-bottom: 5px;
  }

  ul {
    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 0.5px solid #ebebeb;
      padding: 10px 10px 10px 0;

      &:last-child {
        border-bottom: none;
      }

      .media {
        display: flex;
        align-items: center;

        img {
          border-radius: 50%;
        }

        .details {
          margin-left: 8px;

          .icon {
            height: 12px;
            width: 12px;
            margin-bottom: 5px;
            margin-left: 4px;
          }

          h5 {
            font-size: 11px;
            color: #707070;
            font-family: $reqular;
            font-weight: bold;
            margin-bottom: 0px;
            line-height: 14px;
            cursor: pointer;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 130px;
            display: flex;
            align-items: flex-start;
          }

          p {
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 146px;
            cursor: pointer;
            font-size: 13px;
            color: #363636;
            font-family: $reqular;
            font-weight: bold;
            margin-bottom: 0;
          }
        }
      }

      .btn {
        color: #231f20;
        border: 1px solid rgb(222, 222, 222);
        background: transparent;
        border-radius: 25px;
        height: 22px;
        padding: 0;
        width: 71px;
        display: flex;
        justify-content: center;
        font-size: 10px;
        font-family: $medium;
        align-items: center;
      }

      .follow_btn {
        border: none;
        border-radius: 25px;
        background: #4284f3 0% 0% no-repeat padding-box;
        color: #fff;
        height: 22px;
        width: 71px;
        font-size: 10px;
        transition: $transition;

        &:hover {
          transition: $transition;
          opacity: 0.79;
        }
      }

      .is_following {
        border: 1px solid rgb(222, 222, 222);
        background: transparent;
        height: 20px;
        width: 66px;
        font-size: 10px;
      }

      .btn-work {
        width: 250px;
        height: 50px;
        margin: 0 auto;
        padding: 0;
        display: inline-block;
        line-height: 50px;
        text-align: center;
      }
    }
  }
}

.people_you_may_know_card::-webkit-scrollbar {
  display: none;
}

.app_nav {
  .navbar {
    .navbar-collapse {
      justify-content: flex-end;

      .navbar-nav {
        align-items: center;

        .nav-link {
          img {
            height: 30px;
            width: 30px;
            border-radius: 50%;
          }
        }

        .nav-item.dropdown {
          .nav-link {
            color: #231f20;
            font-size: 16px;
            font-family: $medium;
            text-transform: capitalize;
          }
        }
      }
    }
  }
}

.feed_wrapper {
  .head {
    margin-top: 20px;

    @include breakpoint(upto-mobile-mid) {
      width: 95%;
      margin: 0 auto;

      h5 {
        margin: 1rem 0;
      }
    }

    h5 {
      color: #231f20;
      font-size: 18px;
      font-family: $reqular;
      font-weight: bold;
      margin-bottom: 16px;

      @include respond(mobile) {
        font-size: 16px;
      }
    }
  }

  .css-144ovdp {
    display: grid;
    grid-template-rows: repeat(1, 1fr);
  }

  .feed_grid_row.feed-loading-wrap {
    .css-tuh9u2 {
      display: block;
    }
  }

  .feed_grid_row {
    @include breakpoint(upto-XXL-wide-screen) {
      column-width: 320px;

      @include breakpoint(upto-XL-wide-screen) {
        column-gap: 20px;
        column-width: 296px;

        @include breakpoint(upto-X-wide-screen) {
          column-width: 260px;

          @include breakpoint(upto-mobile) {
            columns: 170px;
            column-gap: 12px !important;

            @include breakpoint(upto-mobile-mid) {
              columns: 150px;

              @include breakpoint(upto-mobile-375) {
                columns: 140px;
              }
            }
          }
        }
      }
    }

    margin-bottom: 20px;
    display: inherit !important;

    .css-tuh9u2 {
      page-break-inside: avoid;
      -moz-column-break-inside: avoid;
      break-inside: avoid;
      margin-bottom: 1rem;
      // display: inline-flex;

      @include breakpoint(upto-tablet) {
        white-space: break-spaces;
      }
    }

    @include respond(mobile) {
      margin-bottom: 10px;
      margin-top: 0 !important;
      grid-gap: 5px;
    }

    .feed_grid_col {
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 18px;
      padding: 8px;
      position: relative;

      @include respond(mobile) {
        margin: 0;

        .post_head {
          .MuiButtonBase-root {
            .icon {
              width: 18px !important;
            }
          }

          .post_head_avtar {
            span {
              margin: 5px 4px 5px 0 !important;
            }
          }
        }
      }

      .feed_head {
        position: absolute;
        top: 0;
        top: 0;
        z-index: 1;
        width: 100%;

        h5 {
          color: #fff;
          font-family: $bold;
          font-size: 20px;
          line-height: 26px;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          height: 45px;

          @include respond(smScreen) {
            font-size: 16px;
            line-height: 22px;
            font-family: $demiBold;
          }

          @include respond(mobile) {
            font-size: 13px;
            line-height: 16px;
          }
        }

        .media {
          display: flex;

          img {
            height: 50px;
            width: 50px;
            border-radius: 50%;
            margin-right: 10px;
          }

          .media_details {
            margin-left: 15px;

            h5 {
              color: #363636;
              font-size: 16px;
              font-family: $medium;
              line-height: 20px;
              margin-bottom: 0px;
            }

            p {
              font-size: 14px;
              font-family: $medium;
              line-height: 19px;
              margin-bottom: 0;
            }
          }
        }
      }

      .feed_body {
        .feed_content {
          width: 100%;
          position: relative;
          @include ZoomScaller;
          border-radius: 12px;

          &:before {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            height: 110px;
            transform: matrix(-1, 0, 0, -1, 0, 0);
          }

          &:after {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            height: 150px;
            background: linear-gradient(
              to top,
              rgba(0, 0, 0, 0.8) 0%,
              rgba(0, 0, 0, 0) 100%
            );
            bottom: 0;
            border-radius: 12px;
          }

          img {
            width: 100%;
            cursor: pointer;
            border-radius: 12px;
            aspect-ratio: 1/2;
          }

          .users {
            border-radius: 12px;
            position: absolute;
            bottom: 0;
            z-index: 1;
            left: 0;
            display: flex;
            justify-content: space-between;
            width: 100%;
            padding: 0 10px 10px;
            align-items: flex-end;

            ul {
              li {
                display: flex;
                align-items: center;

                &:first-child {
                  margin-bottom: 10px;
                }

                @include respond(mobile) {
                  margin-bottom: 6px;
                }

                .sb-avatar {
                  @include respond(smScreen) {
                    height: 20px !important;
                    width: 20px !important;
                  }

                  @include respond(mobile) {
                    height: 14px !important;
                    width: 14px !important;
                  }

                  .sb-avatar__text {
                    @include respond(mobile) {
                      height: 14px !important;
                      width: 14px !important;
                      font-size: 10px;
                    }
                  }
                }
              }
            }

            .views_count {
              color: #fff;
              font-family: $reqular;
              font-weight: bold;
              display: flex;
              align-items: center;
              margin-right: 0;

              @include respond(mobile) {
                font-size: 9px;
                transform: translateY(-10px);
              }

              .icon {
                margin-right: 5px;

                @include respond(mobile) {
                  height: 14px;
                  width: 14px;
                }
              }
            }

            strong {
              color: #fff;
              margin-left: 7px;

              @include respond(smScreen) {
                font-size: 12px;
              }

              @include respond(mobile) {
                font-size: 10px;
                line-height: 14px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                display: inline-block;
                max-width: 59px;
              }
            }
          }
        }

        .half {
          height: 50%;

          &:first-child {
            img {
              border-top-left-radius: 10px;
              border-top-right-radius: 10px;
            }
          }

          &:last-child {
            img {
              border-bottom-left-radius: 10px;
              border-bottom-right-radius: 10px;
            }
          }

          img {
            width: 100%;
            height: 100%;
          }
        }

        .feed_bottom {
          .comment_wrap_box {
            padding: 0px 5px;

            .like_comment_details {
              height: auto;
              margin: 5px 0px;
              font-family: $reqular;
              font-weight: bold;
            }

            .view_all_text {
              font-size: 12px;
              font-family: $demiBold;
              line-height: 16px;
              margin-bottom: 15px;
              cursor: pointer;
            }

            .post_title {
              margin: 10px 0px;
              font-size: 13px;
              font-family: $medium;
              line-height: 18px;
            }

            .comment_body {
              max-height: 100%;
              min-height: 100%;
            }

            .comment_ul {
              display: inline-block;
            }
          }

          @include respond(mobile) {
            display: none;
          }

          ul {
            display: flex;

            li {
              padding: 5px 15px 0 0;
              cursor: pointer;

              .icon {
                height: 30px;
                width: 30px;
              }
            }
          }

          .input-group {
            border: 0.5px solid #cccccca3;
            border-radius: 30px;
            background: #ebebeb 0% 0% no-repeat padding-box;

            .input-group-text {
              background-color: transparent;
              border: 0;
              padding: 0 4px 0 4px;

              h5 {
                margin-bottom: 0;
                font-size: 16px;
                font-family: $demiBold;
                cursor: pointer;
                margin-right: 10px;
              }

              .bi {
                font-size: 25px;
                line-height: 0px;
                cursor: pointer;
              }
            }

            .form-control {
              font-size: 11px;
              font-weight: bold;
              color: #707070;
              border: 0;
              height: 38px;
              padding: 6px;
              background: #ebebeb 0% 0% no-repeat padding-box;
            }
          }
        }
      }
    }
  }

  .feed_grid_row::-webkit-scrollbar {
    display: none;
  }

  @include breakpoint(upto-mobile-mid) {
    .post_head_un {
      width: 57px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      @include breakpoint(upto-mobile-375) {
        width: 55px;
      }
    }
  }
}

// .border_bottom {
//   background: linear-gradient(to top, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
// }

.feed_default {
  background-image: url("../img/image_placeholder.png");
}

.feed_bottom {
  ul {
    display: flex;

    li {
      padding: 0 10px 0 0;

      p {
        margin-bottom: 0 !important;
      }
    }
  }

  .like_comment_details {
    margin: 5px 0px;
    height: auto;
    color: #707070;
    font-size: 12px;
    line-height: 19px;
    font-family: $medium;

    .liked-by {
      color: #707070;
      font-family: $reqular;
      font-size: 0.867em;
      text-transform: capitalize;
    }

    strong {
      font-family: $reqular;
      font-weight: bold;
      color: #231f20;
    }
  }

  .comment_body {
    ul {
      li {
        display: flex;
        margin-bottom: 10px;

        img.dp {
          height: 40px;
          width: 40px;
          border-radius: 50%;
          margin-right: 8px;
        }

        .comment_details {
          display: inline-block;
          width: 90%;

          .full_name {
            font-size: 14px;
            font-family: $demiBold;
            color: #363636;

            @include breakpoint(upto-mobile) {
              font-size: 0.87em;
            }
          }

          h5 {
            color: #363636;
            font-size: 14px;
            line-height: 19px;
            font-family: $demiBold;
            margin-bottom: 0;
          }

          .username {
            font-weight: normal;
            color: #363636;
            font-size: 14px;
            height: 19px;
            font-family: $medium;
            margin-bottom: 0 !important;

            @include breakpoint(upto-mobile) {
              font-size: 13px;
              height: 15px;
            }
          }

          .like_comment_icon {
            display: flex;
            padding: 2px;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            .comment {
              margin-bottom: 0 !important;
              font-family: $medium;
              text-transform: capitalize;

              span {
                color: #4284f3 !important;
              }
            }
          }

          .ct_time_like_count {
            display: flex;
            justify-content: space-between;
            width: 100%;
            padding-right: 70px;
            font-size: 10px;
            color: #707070;
            font-family: $medium;

            &:last-child {
              transition: $transition;

              &:hover {
                transition: $transition;
                color: #999;
              }
            }
          }

          .comment_wrap {
            .comment {
              font-size: 12px;
              color: #363636;
              font-family: $reqular;
              font-weight: bold;
            }
          }
        }
      }
    }
  }

  // .input-group {

  //   border-radius: 30px;
  //   .input-group-text {
  //     background-color: transparent;
  //     border: 0;
  //     padding: 0 8px;
  //     h5 {
  //       margin-bottom: 0;
  //       font-size: 16px;
  //       font-family: $demiBold;
  //       cursor: pointer;
  //       margin-right: 10px;
  //     }
  .chakra-button {
    background-color: transparent;
    background: transparent !important;
    font-size: 16px;
    font-family: $reqular;
    font-weight: bold;
    color: #3e4347 !important;
    cursor: pointer;

    &:focus {
      box-shadow: none;
    }
  }

  //     .bi {
  //       font-size: 25px;
  //       line-height: 0px;
  //       cursor: pointer;
  //     }
  //   }
  //   .form-control {
  //     border: 0;
  //     height: 38px;
  //   }
  // }
  .input-group {
    border: 0.5px solid #cccccc;
    border-radius: 30px;

    .input-group-text {
      background-color: transparent;
      border: 0;
      padding: 0 4px 0 4px;

      h5 {
        margin-bottom: 0;
        font-size: 16px;
        font-family: $demiBold;
        cursor: pointer;
        margin-right: 10px;
      }

      .chakra-button {
        background-color: transparent;
        background: transparent !important;
        font-size: 16px;
        font-family: $reqular;
        font-weight: bold;
        color: #3e4347 !important;
        cursor: pointer;

        &:focus {
          box-shadow: none;
        }
      }

      .bi {
        font-size: 25px;
        line-height: 0px;
        cursor: pointer;
      }
    }

    .form-control {
      font-size: 11px;
      font-weight: bold;
      color: #707070;
      border: 0;
      height: 38px;
      padding: 6px;
    }
  }
}

.post_share_modal {
  @include respond(bigScreen) {
    display: flex;
    justify-content: center;
  }

  @include respond(mobile) {
    margin-top: 20px;
    padding: 20px;
  }

  .icon_list {
    padding: 8px;
    width: 160px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 16px;

    button {
      width: 26px;
      height: 26px !important;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.3s ease-in-out;

      i {
        color: #444444f2;
      }

      &:nth-child(5) {
        i {
          font-size: 0.89em;
        }
      }

      &:hover {
        transition: all 0.3s ease-in-out;
        background: rgba(153, 153, 153, 0.214) !important;
        cursor: pointer;

        &:nth-child(1) {
          i {
            color: #3b5998 !important;
          }
        }

        &:nth-child(2) {
          i {
            color: #00acee !important;
          }
        }

        &:nth-child(3) {
          i {
            color: #0077b5 !important;
          }
        }

        &:nth-child(4) {
          i {
            color: #4285f4;
          }
        }

        &:nth-child(5) {
          i {
            color: #000000;
          }
        }
      }
    }
  }
}

.share_post_size {
  width: 791px;

  .feed_bottom {
    ul {
      justify-content: space-between;
      width: 7.89rem;
      align-items: center;

      .icon {
        height: 33px !important;
        width: 33px !important;
      }

      .popover_content {
        box-shadow: 0px 3px 6px #00000029 !important;
      }
    }

    form {
      .input-group-text {
        .chakra-button {
          pointer-events: none;
        }
      }

      .input-group,
      input {
        background: #ebebeb 0% 0% no-repeat padding-box;
        transition: $transition;
        cursor: pointer;

        &:hover {
          transition: $transition;
        }
      }
    }
  }

  .player_col.right {
    .body {
      ul {
        li {
          .chakra-avatar {
            pointer-events: none;
          }

          p {
            pointer-events: none;
          }

          button {
            pointer-events: none;
          }
        }
      }
    }
  }
}

.modal-backdrop.story-status-backdrop.show {
  background: #000000d1 0% 0% no-repeat padding-box;
  opacity: 1;
  backdrop-filter: blur(6px);
}

.story_modal
  .modal-dialog
  .modal-content
  .modal-body
  .react-3d-carousel
  .slider-container
  .slider-right {
  top: 49%;
  height: max-content;
}

.story_modal
  .modal-dialog
  .modal-content
  .modal-body
  .react-3d-carousel
  .slider-container
  .slider-left {
  top: 49%;
  height: max-content;
}

.story_modal {
  .share_story {
    bottom: 60px !important;
    z-index: 99;
  }

  .status-logo-wrap {
    position: absolute;
    top: -30px;
    width: max-content;
    // animation: animeLogo 0.9s forwards;
    left: 16rem;

    @keyframes animeLogo {
      0% {
        left: 0rem;
        opacity: 0.37;
      }

      50% {
        opacity: 0.89;
      }

      90% {
        opacity: 99;
      }

      100% {
        left: 16rem;
      }
    }

    @include breakpoint(upto-tablet) {
      @keyframes animeLogo {
        0% {
          left: 0rem;
          opacity: 0.37;
        }

        50% {
          opacity: 0.89;
        }

        90% {
          opacity: 99;
        }

        100% {
          left: 5rem;
        }
      }
    }

    img {
      width: 15%;
    }
  }

  .close_modal {
    right: 6rem !important;
    transition: $transition;

    &:hover {
      transition: $transition;
      opacity: 0.5;
    }
  }

  .modal-dialog-centered {
    min-height: calc(100% - 0.5rem) !important;
  }

  .modal-dialog {
    max-width: 100% !important;
    margin: 0 auto;

    @include breakpoint(upto-mobile-mid) {
      // max-width: 90%;
    }

    .modal-content {
      background-color: transparent !important;
      border: none;
      border-radius: 12px;

      .modal-body {
        padding: 0px;

        .react-3d-carousel {
          height: calc(100vh - 100px) !important;

          .slider-container {
            .slider-content {
              width: 40%;
              border: 3pxx solid red;

              .slider-single.proactive .slider-single-content {
                transform: translateX(60%) scale(0.66);

                @include breakpoint(upto-tablet) {
                  transform: translateX(86%) scale(0.66);
                }
              }

              .slider-single.preactive .slider-single-content {
                opacity: 0.3;
                transform: translateX(-60%) scale(0.66);

                @include breakpoint(upto-tablet) {
                  transform: translateX(-86%) scale(0.66);
                }
              }
            }

            .slider-left,
            .slider-right {
              div {
                border: none;
                border-radius: 50%;
                height: 29px;
                width: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 0.85em;
                background: #ffffff 0% 0% no-repeat padding-box;
                box-shadow: 0px 3px 6px #00000029;
                opacity: 0.6;
                color: #000;
                transition: $transition;
                position: relative;

                &:hover {
                  opacity: 1;
                  transition: $transition;

                  &::before {
                    content: "next";
                    top: -18px;
                    left: 5px;
                    position: absolute;
                    color: #fff;
                    font-size: 0.85em;
                  }

                  i {
                    transform: translateX(2px);
                    transition: $transition;
                  }
                }

                i {
                  color: #000;
                  transition: $transition;
                }
              }
            }

            .slider-left {
              right: 79%;

              @include breakpoint(upto-mobile) {
                top: 49%;
                height: max-content;

                @include breakpoint(upto-mobile-mid) {
                  right: 85%;
                }
              }

              div {
                &:hover {
                  &::before {
                    content: "previous";
                    left: -5px;
                  }

                  i {
                    transform: translateX(-2px);
                  }
                }
              }
            }

            .slider-right {
              left: 80%;

              @include breakpoint(upto-mobile) {
                top: 49%;
                height: max-content;

                @include breakpoint(upto-mobile-mid) {
                  left: 87%;
                }
              }
            }
          }

          video {
            object-fit: contain;
            aspect-ratio: 1/2;
          }

          .slider-single-content {
            box-shadow: none;
          }
        }

        .player-wrapper {
          position: relative;
          height: calc(100vh - 82px);

          .upper_shadow {
            background: transparent
              linear-gradient(0deg, #231f2000 0%, #000000 100%) 0% 0% no-repeat
              padding-box;
            position: absolute;
            width: 100%;
            height: 115px;
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
          }

          .avtar_username_container {
            left: 12px;
            position: absolute;
            top: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1;

            .username {
              cursor: pointer;
              color: #ffffff;
              font-weight: bold;
              margin-left: 10px;
            }
          }

          .three_dot_icon_container {
            position: absolute;
            right: 0;
            z-index: 1;
            top: 35px;
            width: 45px;
            align-items: center;
            display: flex;
            justify-content: space-evenly;

            i {
              height: 24px !important;
              width: 24px !important;
            }
          }

          video {
            background: #000;

            @include breakpoint(upto-mobile-mid) {
              object-fit: cover;
            }
          }

          .bottom_shadow {
            background: transparent
              linear-gradient(180deg, #231f2000 0%, #000000 100%) 0% 0%
              no-repeat padding-box;
            position: absolute;
            bottom: 0px;
            width: 100%;
            height: 150px;
            border-bottom-left-radius: 12px;
            border-bottom-right-radius: 12px;
          }
        }
      }
    }
  }
}

.player_modal {
  .modal-dialog {
    width: 630px !important;
  }

  .modal-content {
    .video-react {
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
    }

    .video-react .video-react-big-play-button.big-play-button {
      display: none !important;
    }
  }

  video {
    height: 100%;
    width: 100%;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 17px;

    @include breakpoint(upto-tablet) {
      border-bottom-left-radius: 0px;
      border-top-right-radius: 12px;

      @include respond(mobile) {
        width: 100%;
        border-radius: 20px;
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }
    }

    .react-player::-webkit-media-controls {
      display: none;
    }
  }

  .player_row {
    display: grid;
    grid-template-columns: 320px 1fr;

    @include breakpoint(upto-tablet) {
      grid-template-columns: 1fr;

      .player_col.right {
        border-bottom-left-radius: 1.25rem !important;
      }
    }

    .player_col {
      width: 100%;
      overflow: hidden;

      @include respond(mobile) {
        min-height: auto; //475px;
        width: 100%;
      }
    }

    .player_col.left {
      background: #000000b8;
      border-top-left-radius: 1.125rem;
      border-bottom-left-radius: 1.125rem;
      width: 100%;
      overflow: hidden;

      @include breakpoint(upto-mobile) {
        border-bottom-left-radius: 0 !important;
      }

      @include breakpoint(upto-tablet) {
        border-top-right-radius: 1.125rem;
      }
    }

    .player_col.right {
      background: #ffffff;
      position: relative;

      @include respond(bigScreen) {
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;

        @include breakpoint(upto-tablet) {
          overflow: hidden;
        }
      }

      @include respond(smMobile) {
        border-radius: 20px;
        // width: 352px;
      }

      @include respond(mobile) {
        border-radius: 20px;
      }

      .head {
        padding: 10px;
      }

      .media {
        .media_details {
          width: 100%;
          margin-left: 12px;

          h2 {
            color: #231f20;
          }
        }
      }

      .body {
        padding: 0px 10px;

        .chakra-avatar {
          height: 40px;
          width: 40px;
        }

        p {
          font-size: 14px;
          color: #363636;
          line-height: 24px;
          font-family: $medium;

          @include respond(smScreen) {
            line-height: 22px;

            @include breakpoint(upto-mobile-mid) {
              font-size: 0.89em;
            }
          }
        }

        .metadata {
          font-size: 0.8rem !important;
        }

        .text-conti {
          color: #d8463d;
          font-size: 1rem;
          font-family: $medium;
          margin-top: 0.5rem;
          margin-bottom: 1rem !important;

          text-transform: capitalize;

          @include breakpoint(upto-tablet) {
            font-size: 1.2em;
            font-weight: 500;

            @include breakpoint(upto-mobile) {
              font-size: 1.089em;

              @include breakpoint(upto-mobile-mid) {
                margin: 0.2rem 0 0.7rem;
              }
            }
          }
        }

        ul {
          display: flex;

          li {
            padding: 0 16px 0 0;
            text-align: center;

            .btn {
              margin: 0 auto;
              height: 24px;
              line-height: 1;
              font-size: 10px;
              color: #ffff;
              background-color: #4284f3;
              display: flex;
              align-content: center;
              padding: 0;
              width: 70px;
              justify-content: center;
              align-items: center;
            }

            .is_following {
              // background: #4284f3 0% 0% no-repeat padding-box;
              background-color: white;

              border: 1px solid #dedede;
              color: #231f20;
            }

            .btn-work {
              width: 250px;
              height: 50px;
              margin: 0 auto;
              padding: 0;
              display: inline-block;
              line-height: 50px;
              text-align: center;
            }

            .sb-avatar,
            .sb-avatar__text {
              @include respond(smScreen) {
                height: 40px !important;
                width: 40px !important;
              }

              span {
                @include respond(smScreen) {
                  font-size: 15px;
                }
              }
            }

            .dp_name {
              height: 60px;
              width: 60px;
              min-width: 60px;

              line-height: 60px;
            }

            p {
              color: #363636;
              font-size: 12px;
              font-family: $medium;
            }
          }
        }
      }

      .feed_bottom {
        border-top: 1px solid #ebebeb;
        margin-top: 24px;

        section {
          border: 2px solid white;
        }

        @include breakpoint(upto-tablet) {
          margin-top: 1.2rem;

          @include breakpoint(upto-mobile) {
            margin-top: 1rem;
          }
        }

        .share_icon {
          .popover_content {
            border: none;
            background: #ffffff 0% 0% no-repeat padding-box;
            box-shadow: 0px 3px 6px #00000029;

            .icon_list {
              padding: 8px;
              width: 160px;
              display: flex;
              justify-content: space-evenly;
              align-items: center;
              border-radius: 16px;

              button {
                width: 26px;
                height: 26px !important;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: all 0.3s ease-in-out;

                i {
                  color: #444444f2;
                }

                &:nth-child(5) {
                  i {
                    font-size: 0.89em;
                  }
                }

                &:hover {
                  transition: all 0.3s ease-in-out;
                  background: rgba(153, 153, 153, 0.214) !important;
                  cursor: pointer;

                  &:nth-child(1) {
                    i {
                      color: #3b5998 !important;
                    }
                  }

                  &:nth-child(2) {
                    i {
                      color: #00acee !important;
                    }
                  }

                  &:nth-child(3) {
                    i {
                      color: #0077b5 !important;
                    }
                  }

                  &:nth-child(4) {
                    i {
                      color: #4285f4;
                    }
                  }

                  &:nth-child(5) {
                    i {
                      color: #000000;
                    }
                  }
                }
              }
            }
          }
        }

        .comment_body {
          width: 100%;
          // height: 31vh;

          @include breakpoint(upto-landscape-tablet-992) {
            width: 95%;
            margin: 0 auto;

            @include respond(mobile) {
              height: auto;
            }
          }
        }

        .user_disable_comment {
          background-color: white;
          width: 100%;
          position: absolute;
          bottom: 0;
          border-bottom-left-radius: 20px;
          border-bottom-right-radius: 20px;
          padding: 15px;
          border-top: 1px solid #cccccc;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: bold;
          color: #363636;
        }

        ul {
          padding: 0.8rem 0.4rem 0rem;

          li {
            padding: 0 20px 0 0;

            .chakra-avatar {
              cursor: pointer;
            }

            .full_name {
              cursor: pointer;
            }

            .username {
              cursor: pointer;
            }

            .icon {
              height: 30px;
              width: 30px;

              @include breakpoint(upto-mobile) {
                width: 24px;
                height: 24px;
              }
            }
          }
        }

        .like_comment_details {
          margin: 6px 0px 0px 10px !important;
          margin-left: 15px;
          height: auto;
        }

        .like-details {
          position: absolute;
          transform: translate(0px, -13px);

          @include breakpoint(upto-mobile-mid) {
            font-size: 0.694em;
          }
        }

        .like-content {
          position: absolute;
          transform: translate(14px, -6px);
          font-size: 0.75em;
          margin-bottom: 0 !important;

          @include breakpoint(upto-mobile-mid) {
            font-size: 0.694em;
          }
        }

        .comment_ul {
          display: block;
          padding-top: 1rem;

          .child-comment-wrap {
            padding: 0 0.89rem 0rem;
            // height: 243px;
            height: 15.5rem;
            overflow-y: auto;
            overflow-x: hidden;
            @include Scrollbar;

            .chakra-avatar {
              font-weight: bold;
              letter-spacing: 1px;
            }

            &::-webkit-scrollbar {
              width: 0.25rem;
            }

            @include breakpoint(upto-tablet) {
              div {
                &:last-child {
                  li {
                    &:last-child {
                      margin-bottom: 3.5rem;
                    }
                  }
                }
              }

              @include breakpoint(upto-mobile) {
                &::-webkit-scrollbar {
                  width: 0.1875rem;
                }
              }
            }
          }
        }

        .custom_input {
          position: absolute;
          width: 100%;
          bottom: 0;

          .input-group {
            background: #ebebeb 0% 0% no-repeat padding-box;

            input {
              background: #ebebeb 0% 0% no-repeat padding-box;
            }
          }

          @include breakpoint(upto-tablet) {
            background: #fff;
            border-radius: 0 0 1.125rem 1.125;
          }

          .input-group-text {
            button {
              text-transform: capitalize !important;

              &:hover {
                background: transparent;
              }
            }
          }
        }
      }
    }
  }
}

.heart_anim {
  animation-duration: 0.45s;
  animation-name: like-button-animation;
  animation-timing-function: ease-in-out;
  transform: scale(1);
  background-color: transparent;
  border: none;
  cursor: pointer;
  line-height: inherit;
  outline: none;
  padding: 0;

  @keyframes like-button-animation {
    0% {
      transform: scale(1);
    }

    25% {
      transform: scale(1.2);
    }

    50% {
      transform: scale(0.95);
    }

    100% {
      transform: scale(1);
    }
  }
}

.profileDotListItem {
  border-bottom: 0.7px solid #cecece;

  .nested_data {
    width: 100%;
    font-family: $reqular;
    cursor: pointer;
    font-weight: bold;
    font-size: 16px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 1rem 0rem 1rem 0rem;
  }

  div:last-child {
    border-bottom: none;
    padding: 0px 10px;
  }

  &:last-child {
    border-bottom: none;
    padding: 0px;
  }
}

center:last-child {
  border-bottom: none;
}

.reportModalItems {
  border-bottom-right-radius: 21px;
  border-bottom-left-radius: 21px;
  max-height: 400px;
  overflow-y: scroll;
}

.user_list_modal {
  .modal-sm {
    min-width: 360px;

    @include breakpoint(upto-mobile-mid) {
      min-width: 95%;
      margin: 0 auto;
    }
  }
}

.profile-report-modal {
  @include breakpoint(upto-mobile-mid) {
    .modal-content {
      width: 95%;
      margin: 0 auto;

      .reportModalItems {
        center {
          padding: 0.7rem !important;
          font-size: 14px !important;
        }
      }
    }
  }
}

.feed-report-wrap {
  @include breakpoint(upto-mobile-mid) {
    .modal-content {
      width: 95%;
      margin: 0 auto;

      @include breakpoint(upto-mobile-375) {
        .reportModalItems {
          .profileDotListItem {
            button {
              height: 40px !important;
            }
          }
        }
      }
    }
  }
}

.reportModalItems::-webkit-scrollbar {
  display: none;
}

.publick_navbar {
  padding-top: 20px;

  .logo_wrap {
    position: absolute;
    left: 80px;
    top: 20px;

    @include respond(mobile) {
      left: 15px;
      top: 5px;
    }

    @include respond(smScreen2) {
      left: 15px;
      top: 5px;
    }

    img {
      height: 40px;
    }
  }

  @include respond(mobile) {
    padding-top: 0;
  }

  .navbar-nav {
    .nav-link {
      color: #231f20;
      font-family: $demiBold;
      font-size: 18px;
      line-height: 25px;
    }
  }
}

.public_wrapper {
  min-height: calc(100vh - 142px);

  @include respond(mobile) {
    min-height: calc(100vh - 193px);
  }

  h1 {
    text-align: center;
    margin-bottom: 35px;
    font-size: 32px;
    font-family: $medium;
    line-height: 50px;
  }

  p {
    margin-bottom: 35px;
    font-size: 32px;
    font-family: $medium;
    line-height: 36px;
  }

  .body {
    width: 799px;
    margin: 0 auto;
  }
}

body::-webkit-scrollbar {
  display: none;
}

.help_wrapper {
  padding: 50px 0;
  min-height: calc(100vh - 158px);

  h1 {
    text-align: center;
    margin-bottom: 35px;
    font-size: 32px;
    font-family: $medium;
    line-height: 50px;
  }

  p {
    font-size: 16px;
    font-family: $reqular;
    font-weight: bold;
    line-height: 36px;
  }

  .body {
    width: 799px;
    margin: 0 auto;
  }
}

.help {
  .body {
    height: 69vh;
    overflow-y: scroll;
    width: 800px;
    margin: 0 auto;

    h5 {
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 55px;
    }

    .input-group {
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 12px;
      height: 50px;
      margin-bottom: 34px;

      .input-group-text {
        background-color: transparent;
        border: 0;

        .bi {
          font-size: 16px;
        }
      }

      .form-control {
        border: 0;
        height: 50px;
        font-size: 14px;
      }

      .btn-primary {
        background: #f45c43 0% 0% no-repeat padding-box;
        border-radius: 9px;
        height: 35px;
        width: 120px;
        font-size: 16px;
        font-family: $medium;
        color: #ffffff;
      }
    }

    .accordion {
      .accordion-item {
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 6px 12px #00000029;
        border-radius: 9px;
        margin: 0px 11px;
        margin-bottom: 20px;
        border: none;

        .accordion-body {
          font-size: 16px;
          font-weight: bold;
          line-height: 36px;
        }
      }

      .accordion-item:first-of-type .accordion-button {
        border-top-left-radius: 17px;
        border-top-right-radius: 17px;
      }

      .accordion-header {
        .accordion-button {
          background-color: transparent;
          box-shadow: none;
          font-size: 1rem;
          color: #d8463d;
          font-weight: 600;
          font-family: $reqular;

          &:after {
            border-radius: 50%;
            padding: 13px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-position: center;
            filter: grayscale(1);
          }

          @include respond(mobile) {
            font-size: 14px;
          }
        }
      }

      .accordion-collapse {
        border-top: 0.5px solid rgb(204, 204, 204);
      }
    }
  }

  .body::-webkit-scrollbar {
    display: none;
  }
}

footer {
  .container {
    width: 600px;
    position: relative;
  }

  .bottom_footer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
    padding-top: 5px;

    p {
      font-size: 14px;
      font-family: $demiBold;
      line-height: 19px;
      color: #231f20;
    }
  }
}

.user_list_wrap {
  h5 {
    color: #d8463d;
    font-size: 14px;
    font-family: $demiBold;
    line-height: 19px;
    margin-bottom: 5px;
  }

  ul {
    border-top: 1px solid #7d7d7d;
    margin-top: 20px;
    padding-top: 20px;

    li {
      display: flex;
      justify-content: space-between;
      align-items: center;

      border-bottom: 0.5px solid #ebebeb;
      padding: 10px 10px 10px 0;

      &:last-child {
        border-bottom: none;
      }

      .media {
        display: flex;
        align-items: center;

        .details {
          margin-left: 8px;

          .username {
            font-size: 14px;
            font-weight: bold;
            color: #707070;
            font-family: $reqular;

            margin-bottom: 4px;
            line-height: 18px;
            cursor: pointer;
          }

          .full_name {
            font-size: 12px;
            color: #363636;
            font-family: $reqular;
            margin-bottom: 0;
            font-weight: bold;
            line-height: 18px;
            cursor: pointer;
          }

          .desc {
            color: #363636;
          }
        }
      }

      .btn {
        color: #231f20;
        border: 1px solid #dedede;
        background: transparent;
        border-radius: 25px;
        height: 23px;
        padding: 0;
        width: 71px;
        display: flex;
        justify-content: center;
        font-size: 10px;
        font-family: $medium;
        align-items: center;
      }

      .follow_btn {
        border: none;

        background: #4284f3 0% 0% no-repeat padding-box;
        color: #fff;
      }

      .is_following {
        background: #dedede 0% 0% no-repeat padding-box;
        color: #231f20;
      }

      .btn-work {
        width: 250px;
        height: 50px;
        margin: 0 auto;
        padding: 0;
        display: inline-block;
        line-height: 50px;
        text-align: center;
      }
    }
  }
}

a.nav-link.username_link {
  text-transform: capitalize;
}

.stories_wrapper {
  .no_story_card {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 12px;
    height: 198px;
    margin-left: 5px;
    width: 123.5px;
    padding: 0;
    text-align: center;
    display: flex;
    flex-direction: column;

    @include respond(smScreen2) {
      margin-right: 10px;
      padding: 0px;
    }

    @include respond(smScreen4) {
      margin-right: 10px;
      padding: 0px;
    }

    @include respond(smMobile) {
      margin-right: 10px;
      padding: 0px;
    }

    .avatar_box {
      padding: 0px 13px 0px 13px;
      display: flex;
      justify-content: flex-start;
      height: 45%;
      align-items: center;
      justify-content: center;
    }

    .sb-avatar {
      img {
        border: 2px solid white;
      }

      &:nth-child(2) {
        position: relative;
      }

      &:first-child {
        margin-right: -15px;
      }

      &:nth-child(3) {
        margin-left: -15px;
      }
    }

    .body {
      padding: 20px 4px;
      height: 30%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 10px;

      p {
        font-size: 10px;
        font-family: $reqular;
        font-weight: bold;
      }
    }

    .card_footer {
      font-family: $reqular;
      font-weight: bold;
      border-top: 1px solid #e9e8e8;

      height: 25%;
      display: flex;
      align-items: center;
      justify-content: center;

      p {
        font-size: 10px;
        font-family: $medium;
        color: #4284f3;
        margin-bottom: 0;
        cursor: pointer;
      }
    }
  }

  .head {
    h5 {
      color: #231f20;
      font-size: 18px;
      font-family: $demiBold;
      font-weight: bold;
      margin-bottom: 16px;

      @include respond(mobile) {
        font-size: 16px;
      }
    }
  }

  .story_row {
    display: flex;
    overflow-y: hidden;
    overflow-x: scroll;
    align-items: center;
    height: 209px;
    width: 100%;
    margin-left: 0.4rem;

    .story_col {
      height: 198px;
      min-width: 123.5px;
      background-size: cover;
      background-repeat: no-repeat;
      border-radius: 12px;
      margin: 0 5px;
      background-position: center;
      position: relative;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 12px;
      cursor: pointer;

      @include respond(mobile) {
        min-width: 28%;
      }

      &::before {
        content: " ";
        position: absolute;
        transform: matrix(-1, 0, 0, -1, 0, 0);
        background: transparent
          linear-gradient(179deg, #00000000 0%, #000000 100%) 0% 0% no-repeat
          padding-box;
        height: 100px;
        width: 100%;
        left: 0;
        top: 0;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
      }

      &:after {
        content: " ";
        position: absolute;
        background: transparent
          linear-gradient(181deg, #231f2000 0%, #000000 100%) 0% 0% no-repeat
          padding-box;
        height: 100px;
        width: 100%;
        left: 0;
        bottom: 0;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
      }

      h5 {
        color: #fff;
        font-size: 12px;
        font-family: $demiBold;
        position: absolute;
        z-index: 1;
      }

      .top_text {
        top: 0;
        left: 0;
        padding-left: 12px;
        padding-top: 12px;
        padding-bottom: 12px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      .bottom_text {
        width: 100%;
        text-align: start;
        margin-left: 8px;
        bottom: 12px;
        margin-bottom: 0;
      }
    }
  }

  .story_row::-webkit-scrollbar {
    display: none;
  }
}

.modal-open {
  .chakra-popover__body {
    display: none;
  }
}

.navbar {
  .chakra-popover__popper {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 6px #00000029;
    margin-top: 15px !important;

    @include respond(mobile) {
      display: none;
    }

    .chakra-popover__content {
      border: 0;

      @include respond(mobile) {
        width: 100%;
      }

      &:focus {
        box-shadow: none;
      }
    }
  }

  .notification_wrap {
    .noti_head {
      margin-bottom: 20px;
    }

    .user_list_wrap {
      ul {
        li {
          .media {
            .details {
              margin-left: 25px;
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

.notification_wrap {
  .user_list_wrap {
    max-height: 385px;
    overflow-y: auto;
  }

  .empty_noti_wrap {
    height: 100%;
  }

  .noti_head {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h5 {
      margin-bottom: 0;
      font-size: 12px;
      font-family: $medium;
    }
  }

  .noti_body {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    .icon {
      height: 34px;
      width: 34px;
      margin-bottom: 20px;
    }

    h5 {
      font-size: 18px;
      font-family: $medium;
      line-height: 20px;
      color: #363636;

      @include respond(smScreen) {
        font-size: 16px;
        margin-bottom: 5px;
      }
    }

    p {
      font-size: 14px;
      font-family: $medium;
      line-height: 20px;
      color: #707070;

      @include respond(smScreen) {
        font-size: 12px;
      }
    }
  }

  h5 {
    font-size: 14px;
    margin-bottom: 20px;

    .badge {
      font-size: 9px;
      width: 28px;
      height: 17px;
      border-radius: 10px;
      line-height: 11px;
      font-weight: 400;
    }
  }

  .user_list_wrap {
    ul {
      margin-top: 0;
      padding-top: 0;
      border-top: 0;

      li {
        border-bottom: 0;
        padding: 10px;
        margin-bottom: 5px;

        .media {
          padding-right: 25px;
        }

        .details {
          p {
            font-size: 10px;
            color: #707070;
            line-height: 14px;
          }
        }

        .btn {
          height: 25px;
          width: 25px;
          min-width: 25px;
          border-radius: 50%;
        }

        .btn-primary {
          background: #f45c43;
          margin-right: 9px;
        }

        .btn-secondary {
          background: #707070;
        }
      }

      .not_read {
        background: #fff8dd 0% 0% no-repeat padding-box;
      }
    }
  }

  .groups {
    .details {
      h5 {
        font-size: 14px;
        line-height: 19px;
        font-family: $medium;
        color: #363636;
      }

      p.user_name {
        font-size: 12px;
        line-height: 16px;
        font-family: $medium;
        color: #707070;
      }

      p.desc {
        font-size: 10px;
        line-height: 16px;
        font-family: $medium;
        color: #363636;
      }
    }
  }
}

.vertical_nav_wrap {
  padding: 0.3rem;
  height: 100%;

  .vertical_nav_container {
    height: 100% !important;
  }

  .suggestion_other_wrap {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .nav-link {
    color: #231f20;
    font-size: 16px;
    font-weight: bold;
    line-height: 43px;
    font-family: $medium;
    display: flex;
    align-items: center;

    .icon {
      margin-right: 40px;
    }

    span {
      z-index: 20;
    }
  }

  .active {
    background: #ebebeb 0% 0% no-repeat padding-box;
    position: relative;

    &:hover {
      &::before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        animation: anime 0.5s linear;
      }
    }
  }

  @keyframes anime {
    0% {
      width: 0;
      transition: $transition;
      background: #c1c1c1;
    }

    100% {
      width: 100%;
      transition: $transition;
      background: inherit;
    }
  }

  h5 {
    font-size: 16px;
    line-height: 33px;
    color: #3e4347;
    font-family: $medium;
    font-weight: 500;

    margin: 0;
  }
}

.edit_profile_wrap {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 12px;
  border-radius: 12px 12px 0 0;
  margin: 0 0 20px;
  padding: 20px 30px 20px 20px;
  height: 100%;

  .title {
    margin-bottom: 36px;
    color: #231f20;
    font-size: 23px;
    font-family: $reqular;
    font-weight: bold;
  }

  .edit_head {
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    @include respond(mobile) {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .profile_details {
      @include respond(mobile) {
        margin-top: 20px;
        text-align: center;
      }
    }
  }

  .edit_body {
    margin-top: 40px;

    .tags_row {
      display: flex;
      align-items: center;

      @include respond(mobile) {
        display: flex;
        flex-direction: column;
      }

      @include respond(smScreen3) {
        display: flex;
        flex-direction: column;
      }
    }

    .form-control {
      height: 38px;
      background: #ffffff 0% 0% no-repeat padding-box;
      border: 1px solid #e0e0e0;
      border-radius: 5px;
    }

    textarea.form-control {
      height: auto;
      resize: none;
    }

    .input-group {
      background: #ffffff 0% 0% no-repeat padding-box;
      // border: 1px solid #e0e0e0;
      border-radius: 5px;
      cursor: pointer;

      .input-group-text {
        background-color: transparent;
        border: 0;
      }

      .form-select {
        border: 0;
      }
    }

    .button_wrap {
      display: flex;
      justify-content: center;
      margin-top: 40px;

      .chakra-button {
        height: 55px;
        width: 240px;
        background: #d8463d 0% 0% no-repeat padding-box;
        box-shadow: 0px 2px 4px #00000040;
        border-radius: 30px;
        color: #fff;
      }
    }
  }
}

.main_body {
  .container {
    @include respond(mobile) {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.main_body::-webkit-scrollbar {
  display: none;
}

.profile_wrap {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 12px;
  margin-bottom: 10px;

  @include respond(mobile) {
    margin: 20px 0 0;

    border-radius: 23px 23px 0px 0px;
  }

  .profile_head {
    position: relative;

    .profile-grid-wrap {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      justify-content: space-around;

      .first-grid {
        position: relative;

        &:after {
          content: " ";
          position: absolute;
          right: 0px;
          background-color: #da483e;
          height: 80px;
          width: 3px;
          top: 50px;
        }
      }
    }

    .profile_detail_wrap {
      @include respond(mobile) {
        padding-left: 0;
      }

      .profile_dp_left {
        display: flex;
        width: 100%;
        position: relative;
        justify-content: center;
        // @include breakpoint(upto-mobile-mid){
        //    .Profle-child-circle{
        //      width: 89px!important;
        //      top: 20px!important;
        //      & > div{
        //       & > div{
        //         & > div{
        //           margin-top: -90.3% !important;
        //           left: -4.4px;
        //         }
        //       }
        //      }
        //      .chakra-avatar{
        //       width: 79%!important;
        //       height: 89%!important;
        //       div{
        //         font-size: calc(5rem / 2.5);
        //       }
        //      }
        //      .CircularProgressbar{
        //       width: 90%;
        //      }
        //    }
        // }
      }

      .profile_dp_wrap {
        .chakra-avatar {
          cursor: pointer;
          position: initial;
          @include ZoomScaller;

          &:hover {
            img {
              transform: scale(1.112);
            }
          }

          @include respond(mobile) {
            &:after {
              left: 170px;
            }
          }
        }
      }

      h5 {
        text-align: left;
        font-size: 32px;
        font-family: $bold;
        margin-top: 0.5rem;
        margin-bottom: 0;
        text-transform: capitalize;

        @include breakpoint(upto-tablet) {
          font-size: 26px;
          margin-top: 0;

          span {
            font-size: 24px;
            line-height: 1.4;
            display: block;
          }

          @include respond(mobile) {
            font-size: 23px;
            line-height: 35px;

            span {
              font-size: 19px;
              line-height: 1;
            }
          }
        }

        span {
          display: block;
        }

        small {
          letter-spacing: 0.67px;
          color: #707070;
          font-size: 25px;
          font-family: $medium;
          line-height: 1.5;

          @include breakpoint(upto-mobile-mid) {
            font-size: 16px;
            line-height: 1.2;
          }
        }
      }

      p {
        color: #231f20;
        font-size: 16px;
        font-family: $medium;
        margin-left: 1px;
        line-height: 24px;
        margin: 0.1rem 0 0;
        display: flex;
        align-items: center;

        .icon {
          height: 16px;
          width: 16px;
          margin-left: 6px;
          margin-top: 6px;
        }

        @include respond(mobile) {
          font-size: 12px;
          line-height: 13px;
        }
      }
    }

    .follow_details {
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      height: 100%;

      ul {
        @include breakpoint(upto-mobile) {
          height: 100% !important;
        }

        li {
          margin-bottom: 20px;
          cursor: pointer;

          strong {
            font-size: 18px;
            font-family: $demiBold;
            line-height: 26px;

            @include breakpoint(upto-mobile-mid) {
              font-size: 16px;
            }
          }

          p {
            margin-bottom: 0;
            color: #707070;
            font-size: 14px;
            font-family: $medium;

            @include breakpoint(upto-mobile-mid) {
              font-size: 12px;
            }
          }
        }

        button {
          transition: $transition;
          height: 36px !important;
          font-family: $medium;
          font-size: 1em !important;
          letter-spacing: 1px;
        }
      }

      .btn {
        height: 41px;
        font-size: 20px;
        transition: $transition;

        @include respond(mobile) {
          position: absolute;
          height: 30px;
          width: 186px;
          border-radius: 25px;
          font-size: 14px;
          bottom: 0px;
        }
      }
    }
  }

  .profile_body {
    .nav {
      justify-content: space-evenly;
      border-bottom: 0;
      border-top: 0.5px solid #ececec;

      @include respond(bigScreen) {
        justify-content: space-between;
      }

      @include respond(bigScreen) {
        justify-content: space-evenly;
      }

      .nav-link {
        outline: none;
        border: 0;
        padding-top: 20px;
        transition: none;
        opacity: 0.3;

        &:hover {
          border: 0;
        }
      }

      .active {
        border: 0;
        position: relative;
        opacity: 1;

        &:before {
          content: " ";
          position: absolute;
          height: 2px;
          background: #f45c43;
          width: 100%;
          top: -1.5px;
          left: 0px;

          @include respond(mobile) {
            width: 68px;
            top: 0;
            left: 3px;
          }
        }
      }
    }

    .tab-content {
      .taggs_ul {
        display: flex;
        margin-top: 35px;
        font-weight: bolder;
        width: 100%;

        li {
          float: left;
          width: 50%;
          display: flex;
          align-items: center;
          margin-bottom: 40px;
          cursor: pointer;

          @include respond(mobile) {
            width: 100%;
          }

          .icon {
            margin-right: 13px;
          }
        }
      }

      .skills_wrap {
        margin-top: 20px;

        .card {
          background: #ffffff 0% 0% no-repeat padding-box;
          box-shadow: 0px 3px 6px #00000029;
          border-radius: 18px;
          margin-bottom: 20px;
          min-height: 170px;

          .card-header {
            letter-spacing: 0.42px;
            color: #363636;
            font-size: 14px;
            font-family: $demiBold;
            background: #fff;
            border-top-left-radius: 18px;
            border-top-right-radius: 18px;
            text-align: center;
            padding: 8px 12px;
          }

          .card-body {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }
}

.profile-wrapper {
  position: relative;

  @include breakpoint(upto-tablet-800) {
    margin-bottom: 0px !important;
    height: 100vh !important;

    @include breakpoint(upto-tablet) {
      overflow-x: hidden;
    }
  }

  .taggs_ul {
    position: absolute;
    bottom: 1.9rem;
    left: 1rem;
    width: 95% !important;

    @include breakpoint(upto-tablet) {
      position: fixed;
      bottom: 1rem;
      left: 1.8rem;
      width: 90%;
    }

    li {
      margin-bottom: 0 !important;
    }
  }
}

span.dp_upload {
  position: absolute;
  bottom: 0;
  right: 77px;
  cursor: pointer;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  height: 36px;
  width: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.no_info_wrap {
  text-align: center;
  padding: 68px 0 80px;

  h5 {
    font-size: 22px;
    color: #3e4347;
    line-height: 30px;
    margin-bottom: 30px;
  }

  p {
    color: #707070;
    font-size: 18px;
    line-height: 25px;
  }

  .btn {
    width: 200px;
    height: 55px;
    background: #d8463d 0% 0% no-repeat padding-box;
    border-radius: 28px;
    margin-top: 30px;
  }
}

.add_bio_wrap {
  textarea {
    resize: none;
    height: auto;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 0.5px solid #cccccc;
    border-radius: 12px;

    &:focus {
      border: 1px solid #cccccc;
    }

    @include placeholder {
      font-size: 22px;
      color: #cccccc;
    }
  }

  .button_wrap {
    display: flex;
    justify-content: center;

    .btn {
      height: 44px;
      width: 300px;
      background: #d8463d 0% 0% no-repeat padding-box;
      border-radius: 22px;
    }
  }
}

.tags_wrap {
  .body {
    padding: 0 110px;
    max-height: 300px;
    overflow-y: auto;
  }

  ul {
    li {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;
      color: #363636;
      font-size: 16px;
      font-family: $reqular;
    }
  }

  .button_wrap {
    display: flex;
    justify-content: center;
    border-top: 1px solid #dee2e6;
    width: 100%;
    padding: 10px 10px 0;

    .btn {
      height: 44px;
      width: 300px;
      background: #d8463d 0% 0% no-repeat padding-box;
      border-radius: 22px;
    }
  }
}

.suggestions_wrap {
  .title {
    color: #d8463d;
    font-size: 18px;
    font-family: $demiBold;
    line-height: 25px;
  }

  .mb_space {
    margin-bottom: 25px;
  }

  ul {
    li {
      .follow_btn {
        border-radius: 25px;
        background: #4284f3 0% 0% no-repeat padding-box;
        color: #fff;
        height: 23px;
        width: 71px;
        font-size: 10px;
        transition: $transition;

        &:hover {
          transition: $transition;
          opacity: 0.79;
        }
      }

      .follow_btn.is_following {
        color: black;
        background: #c8c8c8;
      }
    }
  }
}

.on_mobile {
  @include respond(bigScreen) {
    display: none;
  }

  @include respond(midScreen) {
    display: none;
  }

  @include respond(mobile) {
    display: block;
  }
}

.privacy_setting_wrap {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 12px 12px 0 0;
  margin: 0 0 20px;
  padding: 1.5rem;
  height: 100%;

  .privacy-footer {
    display: none;
  }

  @include respond(mobile) {
    margin: 1rem 0 0;
    padding: 25px 3px 3px;
    border-radius: 23px 23px 0 0;
    box-shadow: none;

    .privacy-footer {
      display: block;

      .right_footer {
        position: static;

        ul {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-gap: 0 4rem;
          width: 90%;
          margin: 1rem auto;
        }
      }

      .social-footer {
        flex-wrap: wrap;
        width: 90%;
        margin: 0 auto;
        justify-content: space-around;

        p {
          width: 100%;
          padding: 0 0 0.8rem;
        }

        a {
          width: 46%;

          img {
            height: 1.8rem;
          }
        }
      }
    }
  }

  .title {
    margin-bottom: 36px;
    color: #231f20;
    font-size: 23px;
    font-weight: bold;
    line-height: 43px;
    font-family: $reqular;

    @include respond(mobile) {
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      margin-bottom: 19px;
    }
  }

  .body {
    .title {
      text-align: left;
      margin-bottom: 8px;
    }

    ul {
      li {
        display: flex;
        justify-content: space-between;
        // margin-bottom: 48px;

        .privacy_li_it {
          color: #231f20;
          font-size: 18px;
          line-height: 24px;
          font-family: $medium;
          margin: 0.8rem 0;
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;

          @include respond(mobile) {
            font-size: 14px;
            line-height: 20px;
            background: #f3f4f6 0% 0% no-repeat padding-box;
            border-radius: 12px;
            padding: 14px 12px;
            margin-bottom: 14px;
          }
        }

        p {
          color: #707070;
          font-size: 13px;
          line-height: 19px;
          font-family: $medium;
          margin: 0.5rem 0;

          @include respond(mobile) {
            font-size: 12px;
            line-height: 19px;
            text-align: center;
          }
        }
      }
    }

    .inner_wrap {
      .sub_title {
        color: #231f20;
        font-size: 16px;
        line-height: 19px;
        font-family: $medium;
        margin: 1rem 0 0;

        @include respond(mobile) {
          font-size: 16px;
          line-height: 20px;
          margin-bottom: 12px;
        }
      }

      ul {
        @include respond(mobile) {
          font-size: 14px;
          line-height: 20px;
          background: #f3f4f6 0% 0% no-repeat padding-box;
          border-radius: 12px;
          padding: 14px 12px;
        }

        li {
          border-bottom: 1px solid #e4e4e4;
          margin-bottom: 0;
          align-items: center;
          padding: 9px 0;

          h5 {
            margin: 0.4rem 0;
          }

          @include respond(mobile) {
            padding: 0;
          }

          &:last-child {
            border-bottom: 0;
          }

          h5 {
            margin-bottom: 0;

            @include respond(mobile) {
              font-size: 14px;
              line-height: 20px;
              background: transparent;

              padding: 5px 0;
            }
          }
        }
      }
    }
  }
}

.help_support_wrap {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 12px 12px 0 0;
  margin: 0 0 20px;
  padding: 1.5rem;
  height: 100%;

  @include breakpoint(upto-mobile) {
    margin: 1rem 0 0;
    padding: 1.5rem 1.5rem 0;

    h5 {
      margin-bottom: 0.5rem !important;
    }

    ul {
      li a {
        font-size: 0.835em !important;
      }
    }
  }

  .title {
    margin-bottom: 0.5rem;
    color: #231f20;
    font-size: 23px;
    font-weight: bold;
    line-height: 43px;
    font-family: $reqular;

    @include respond(mobile) {
      font-size: 16px;
      line-height: 20px;
      text-align: left;
      margin-bottom: 19px;

      & .on_web {
        display: block !important;
      }
    }
  }

  ul {
    margin-bottom: 22px;

    li {
      border-bottom: 1px solid #e4e4e4;
      padding: 0.5rem 0;

      &:last-child {
        border-bottom: 0;
      }

      a {
        color: #231f20;
        font-size: 17px;
        line-height: 20px;
        font-family: $medium;
      }
    }
  }

  .delete {
    color: #d8463d;
    font-size: 17px;
    line-height: 20px;
    font-family: $medium;
    cursor: pointer;
  }
}

.delete_account_modal {
  @media (min-width: 576px) {
    .modal-sm {
      max-width: 365px;
    }
  }

  .modal-content {
    border: none;

    .modal-body {
      .icon_wrap {
        background: #d8463d 0% 0% no-repeat padding-box;
        width: 56px;
        height: 56px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto 17px;
      }

      p {
        text-align: center;
      }

      .buttons {
        display: flex;
        justify-content: space-between;
        margin-top: 33px;
        align-items: center;

        .btn {
          height: 40px;
          width: 154px;
        }
      }
    }
  }
}

.navbar_container {
  // height: 105px;
  @include respond(mobile) {
    width: 100%;
  }
}

.search_box {
  margin-right: 10px;
  width: 43%;

  @include respond(mobile) {
    display: flex;
    margin-top: 6px;
    width: 100%;
  }

  @include respond(smlScreen) {
    display: flex;
    margin-top: 6px;
    width: 100%;
  }

  @include respond(tablet) {
    margin-top: 6px;
    width: 100%;
  }

  @include respond(smMobile) {
    width: 100%;
  }

  .search_group {
    .chakra-input {
      background: #ffffff 0% 0% no-repeat padding-box;
      border-radius: 9px;
      border-radius: 9px;
      border: 2px solid #cccccc;
      height: 42px;

      &:focus {
        box-shadow: none;
        border: 0;
      }
    }

    .chakra-input__left-element,
    .chakra-input__right-element {
      height: 42px;
    }

    .btn {
      background: #d8463d 0% 0% no-repeat padding-box;
      border-radius: 9px;
      width: 91px;
      height: 34px;
      margin-right: 39px;
      font-size: 15px;
    }
  }
}

.public_footer {
  border-top: 1px solid #ccc;
  background-color: white;
  position: absolute;
  width: 100%;

  &::before {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    background-image: url("../img/big_bottom_layer.svg");
    height: 218px;
    width: 392px;
    background-size: contain;
    background-repeat: no-repeat;

    @include respond(mobile) {
      background-image: url("../img/bottom_layer_small.svg");
      height: 135px;
      width: 243px;
    }
  }

  @include respond(mobile) {
    position: relative !important;
  }

  .bottom_footer {
    @include respond(mobile) {
      justify-content: flex-start;
    }
  }
}

span.add_tag_tex {
  height: 50px;
  color: #868e96;
  font-size: 16px;
  line-height: 24px;
  font-family: $medium;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.media {
  display: flex;
  align-items: center;

  .sb-avatar,
  .sb-avatar__text {
    @include respond(smScreen) {
      height: 40px !important;
      width: 40px !important;
    }

    span {
      @include respond(smScreen) {
        font-size: 15px;
      }
    }
  }

  img {
    border-radius: 50%;
  }

  .media_details {
    h5 {
      color: #231f20;
      font-size: 22px;
      line-height: 30px;
      font-weight: bold;
      font-family: $reqular;
      margin-bottom: 0;

      @include respond(smScreen) {
        font-size: 16px;
        line-height: 24px;
      }
    }

    p {
      color: #707070;
      font-size: 14px;
      line-height: 19px;
      font-weight: bold;
      font-family: $reqular;
    }
  }
}

.early_access {
  display: flex;
  flex-direction: column;

  .early_access_form_box {
    .chakra-button {
      margin-top: 15px;
      width: 100%;
      margin: 15px 0;
    }

    .chakra-input.error {
      border: 1px solid red;
    }

    .error_text {
      font-size: 12px;
      margin-left: 15px;
      font-family: $medium;
    }
  }

  .wrap_box {
    flex: 1;
  }

  @include respond(mobile) {
    height: 100vh;
    background-image: none;
  }

  .logo_wrap {
    @include respond(mobile) {
      left: 24px;
      top: 29px;
    }

    img {
      height: 40px;
    }
  }

  .head_box {
    margin-top: -70px;

    @include respond(mobile) {
      margin-top: 0;
    }
  }

  .heading {
    font-family: $demiBold;
    font-size: 28px;
    line-height: 50px;

    @include respond(mobile) {
      font-size: 16px;
      line-height: 30px;
    }
  }

  .chakra-input {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 9px;
    width: 300px;
    height: 45px;
    border: 0;
    font-size: 14px;
    font-family: $medium;

    @include respond(mobile) {
      flex: 1;

      font-size: 12px;
      height: 38px;
    }

    &:focus {
      border: 0;
      box-shadow: 0px 3px 6px #00000029;
    }
  }

  .chakra-button {
    // background: #4284f3 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 9px;
    width: 144px;
    height: 45px;
    font-size: 14px;
    font-family: $demiBold;
    color: #ffffff;

    @include respond(mobile) {
      width: 33%;
      font-size: 12px;
      height: 38px;
    }

    &:hover {
      background: #f45c43 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000029;
    }
  }

  .input_box {
    margin-top: 70px;
  }
}

.thankyou_wrap {
  background: transparent
    linear-gradient(180deg, #fffaed 0%, #fffdf8 39%, #fffefc 70%, #ffffff 100%)
    0% 0% no-repeat padding-box;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @include respond(mobile) {
    display: inline-block;
  }

  .t_box {
    @include respond(mobile) {
      height: calc(100vh - 215px);
    }
  }

  .logo_wrap {
    @include respond(mobile) {
      left: 10px;
      top: 30px;

      &:after {
        display: none;
      }
    }

    img {
      height: 40px;
    }
  }

  .bottom_nav {
    padding-bottom: 15px;

    .nav {
      margin-bottom: 0;
    }
  }

  .thankyou_box {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 22px;
    width: 400px;
    height: 428px;
    text-align: center;
    padding: 40px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    position: relative;

    @include respond(mobile) {
      margin: 0 15px;
      width: 100%;
    }

    .animation_box {
      background-image: url("../img/animation.gif");
      height: 200px;
      width: 267px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      position: absolute;
      top: 0;
    }

    .content_box {
      position: relative;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
    }

    .heading {
      font-size: 30px;
      font-family: $demiBold;
    }

    .text_sub {
      font-size: 18px;
      font-family: $demiBold;
    }

    .text_middle {
      font-size: 18px;
      font-family: $demiBold;
    }

    .text_last {
      font-size: 14px;
      font-family: $medium;
    }

    ul {
      display: flex;

      li {
        padding: 0 17px;
        cursor: pointer;
      }
    }
  }
}

.whishlist_box {
  padding: 30px 0;
  text-align: center;

  .heading {
    // font-size: 22px;
    font-family: $demiBold;
    // background: #f45c43 0% 0% no-repeat padding-box;
    // border-radius: 4px;
    // width: 120px;
    // height: 51px;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    // color: #ffffff;
    // margin: 0 auto 20px;
    width: 93%;
    font-size: 24px;
    padding-bottom: 20px;
    text-align: center;

    line-height: 48px;
  }

  .ahead_text {
    letter-spacing: 0px;
    color: #231f20;
    font-size: 16px;
    font-family: $medium;
    line-height: 44px;
    margin-bottom: 0;
  }

  .copy_box {
    position: relative;

    margin: 0 auto 20px;

    .chakra-input {
      background: #f0f0f0 0% 0% no-repeat padding-box;
      border-radius: 14px;
      height: 32px;
      border: 0;

      &:focus {
        z-index: inherit;
        border: 0;
      }
    }

    .chakra-input__right-addon {
      position: absolute;
      right: 0;
      background: var(--unnamed-color-4284f3) 0% 0% no-repeat padding-box;
      background: #4284f3 0% 0% no-repeat padding-box;
      border-radius: 14px;
      color: #fdfdfd;
      font-size: 11px;
      font-family: $medium;
      height: 32px;
      cursor: pointer;
      display: flex;
      align-items: center;
      line-height: 0;
    }
  }

  p {
    font-size: 14px;
    font-family: $medium;
    margin-bottom: 24px;
    line-height: 22px;
  }

  ul {
    display: flex;
    justify-content: space-around;
    margin-top: 35px;
  }
}

.wrap_box {
  @include respond(mobile) {
    height: calc(100vh - 175px) !important;
  }
}

.player-wrapper {
  position: relative;
  height: 100%;

  .share_icon {
    list-style-type: none;

    .popover_content {
      border: none;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000029;

      .icon_list {
        padding: 8px;
        width: 160px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        border-radius: 16px;

        button {
          width: 26px;
          height: 26px !important;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: all 0.3s ease-in-out;

          i {
            color: #444444f2;
          }

          &:nth-child(5) {
            i {
              font-size: 0.89em;
            }
          }

          &:hover {
            transition: all 0.3s ease-in-out;
            background: rgba(153, 153, 153, 0.214) !important;
            cursor: pointer;

            &:nth-child(1) {
              i {
                color: #3b5998 !important;
              }
            }

            &:nth-child(2) {
              i {
                color: #00acee !important;
              }
            }

            &:nth-child(3) {
              i {
                color: #0077b5 !important;
              }
            }

            &:nth-child(4) {
              i {
                color: #4285f4;
              }
            }

            &:nth-child(5) {
              i {
                color: #000000;
              }
            }
          }
        }
      }
    }
  }

  .progress {
    height: 2px;
    position: absolute;
    top: 10px;
    width: 100%;
    flex-grow: 1;
    border-radius: 4px;
    display: flex;
    background-image: -webkit-linear-gradient(
      left,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(88, 89, 104, 0.5) 50.001%,
      rgba(88, 89, 104, 0.5) 100%
    );
    background-repeat: no-repeat;
    background-size: 200%;
    background-color: #666;
    background-position: 100% 50%;
    animation-timing-function: linear;
    animation-delay: 0.2s;
  }

  .progress.active {
    animation-name: Loader;

    .progress.passed {
      background-position: 0 0;
    }
  }

  @keyframes Loader {
    0% {
      background-position: 100% 0;
    }

    100% {
      background-position: 0 0;
    }
  }

  video {
    video::-webkit-media-controls {
      display: none;
    }
  }

  .story_player {
    aspect-ratio: 1/2;
  }
}

.react-player {
  // position: absolute;
  // top: 0;
  // left: 0;

  @include breakpoint(upto-mobile-mid) {
    position: relative;
  }

  .react-player__preview {
    border-radius: 40px 0px 0px 40px;
  }
}

.react-player::-webkit-media-controls-play-button {
  display: none;
}

// .react-player::-webkit-media-controls-play-button{
//   display: none;
// }

.play_icon_box {
  position: absolute;
  top: 50%;
  left: 50%;
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 0.35;
  height: 70px;
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;

  svg {
    font-size: 35px;
  }
}

.video_poster {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 40px 0 0 40px;

  @include respond(mobile) {
    border-radius: 40px;
  }
}

.about_box {
  .logo_wrap {
    position: absolute;
    z-index: 2;
    left: 80px;
    top: 20px;

    img {
      height: 40px;
    }

    @include respond(midScreen) {
      left: 80px;
      top: 20px;
    }

    @include respond(mobile) {
      left: 24px;
      top: 29px;
    }
  }

  .banner_video {
    position: relative;

    // padding-top: 56.25%;
    &:before {
      content: "";
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      background-image: url("../img/about_bg.png"),
        linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8));
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }

    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    @include respond(mobile) {
      height: 515px;
    }

    .video_player {
      position: absolute;
      top: 0;
      left: 0;
    }

    .content_box {
      text-align: center;

      .mission_text {
        font-size: 22px;
        font-family: $demiBold;
        line-height: 59px;
        color: #fff;
        text-transform: uppercase;
        margin-bottom: 10px;

        @include respond(mobile) {
          font-size: 12px;
          line-height: 25px;
          margin-bottom: 30px;
        }
      }

      .mission_heading {
        font-size: 70px;
        font-family: $bold;
        line-height: 90px;
        margin-bottom: 20px;
        text-transform: uppercase;
        color: #fff;

        @include respond(mobile) {
          font-size: 25px;
          line-height: 39px;
        }
      }

      .mission_pointer {
        font-size: 22px;
        font-family: $demiBold;
        line-height: 59px;
        margin-top: 50px;
        text-transform: uppercase;
        color: #fff;

        @include respond(smScreen) {
          font-size: 20px;
          line-height: 25px;
        }
      }
    }
  }

  .box_section {
    padding: 80px 0;

    @include respond(mobile) {
      padding: 40px 10px;
    }

    img {
      @include respond(mobile) {
        height: 300px;
        margin: 0 auto;
      }
    }

    .box_section_content {
      display: flex;
      align-items: center;
    }

    .heading {
      font-size: 50px;
      font-family: $bold;
      line-height: 80px;
      margin-bottom: 20px;

      @include respond(midScreen) {
        font-size: 35px;
        line-height: 60px;
      }

      @include respond(mobile) {
        font-size: 20px;
        line-height: 85px;
        margin-bottom: 0;
      }
    }

    p {
      font-size: 19px;
      font-family: $medium;
      line-height: 48px;

      @include respond(mobile) {
        font-size: 14px;
        line-height: 30px;
      }
    }

    .top_text {
      font-size: 20px;
      font-family: $medium;
      line-height: 30px;
      text-transform: uppercase;
      margin-bottom: 40px;
      position: relative;

      @include respond(midScreen) {
        font-size: 16px;
        line-height: 25px;
      }

      &:after {
        content: "";
        position: absolute;
        bottom: -15px;
        height: 2px;
        width: 80px;
        background: #f45c43;
        left: 0;
      }
    }

    .title_text {
      font-size: 22px;
      font-family: $demiBold;
      line-height: 50px;
      text-transform: uppercase;
      margin-bottom: 20px;

      @include respond(midScreen) {
        font-size: 18px;
        line-height: 25px;
      }
    }

    .resources_box {
      ul {
        li {
          display: flex;
          justify-content: space-between;
          font-size: 25px;
          font-family: $demiBold;

          border-bottom: 1px solid #e4e4e4;
          padding: 25px 0;
          cursor: pointer;

          @include respond(mobile) {
            font-size: 16px;
          }
        }
      }
    }
  }
}

.gray_bg {
  background: #f5f6f8 0 0 no-repeat padding-box;
}

.jobs_wrapper {
  p {
    margin-bottom: 0;
  }

  .head_box {
    margin-top: 25px;
    padding: 0 33px;

    .input-group {
      @include respond(mobile) {
        padding: 0 0;
        margin-bottom: 0;
      }
    }

    @include respond(mobile) {
      padding: 0 0;
    }
  }

  .view_job_text {
    font-size: 18px;
    font-family: $demiBold;
    line-height: 40px;
    text-align: center;

    @include respond(mobile) {
      font-size: 12px;
      font-family: $medium;
      line-height: 50px;
      text-align: left;
    }
  }
}

.body {
  width: 100%;
}

.filter_text_top_box {
  border-bottom: 1px solid #e4e4e4;

  .filter_text_top_left_box {
    width: 30%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include respond(mobile) {
      width: 100%;
      justify-content: flex-end;
      margin-top: -30px;
    }
  }

  .filter_text {
    font-size: 16px;
    font-family: $medium;
    line-height: 50px;
    color: #231f20;

    @include respond(mobile) {
      color: #f45c43;
      font-size: 16px;
      font-family: $demiBold;
    }
  }

  .reset_text {
    font-size: 16px;
    font-family: $medium;
    line-height: 50px;
    color: #f45c43;
    cursor: pointer;

    @include respond(mobile) {
      display: none;
    }
  }
}

.jobs_body_box {
  display: flex;
  min-height: calc(100ch - 424px);

  .filter_left_box {
    width: 30%;

    @include respond(mobile) {
      display: none;
    }

    .title_text {
      color: #231f20;
      font-size: 18px;
      font-family: $demiBold;
      line-height: 50px;
      margin-bottom: 10px;
    }

    .chakra-checkbox {
      .chakra-checkbox__label {
        color: #707070 !important;
        font-size: 18px;
        margin-left: 15px;
      }
    }

    .css-xxkadm {
      border: 1px solid #e2e8f0 !important;
    }
  }

  .jobs_list_box {
    flex: 1;
    border-left: 1px solid #e4e4e4;
    padding-left: 20px;
    padding-top: 20px;

    @include respond(mobile) {
      border-left: 0;
      padding-left: 0;
    }

    ul {
      li {
        margin-bottom: 25px;
      }
    }

    .job_card {
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 9px;
      padding: 18px 23px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;

      @include respond(mobile) {
        padding: 11px 14px;
      }

      svg {
        font-size: 45px;

        @include respond(mobile) {
          font-size: 25px;
        }
      }

      .job_title {
        font-size: 20px;
        font-family: $demiBold;
        line-height: 40px;

        @include respond(mobile) {
          font-size: 13px;
          font-family: $demiBold;
          line-height: 28px;
        }
      }

      .job_location {
        color: #707070;
        font-size: 16px;
        font-family: $demiBold;
        line-height: 30px;

        @include respond(mobile) {
          font-size: 10px;
          font-family: $demiBold;
          line-height: 25px;
        }
      }

      .job_type {
        color: #707070;
        font-size: 14px;
        font-family: $demiBold;
        line-height: 30px;
        text-transform: capitalize;

        @include respond(mobile) {
          font-size: 9px;
          font-family: $demiBold;
          line-height: 25px;
        }
      }
    }
  }
}

.jobs_wrapper.filter_open {
  .jobs_body_box {
    // flex-wrap: wrap;
    .filter_left_box {
      display: inline-block;
      width: 100%;
    }
  }
}

.job_details {
  .head_box {
    border-bottom: 1px solid #e4e4e4;

    .heading {
      color: #231f20;
      font-size: 25px;
      font-family: $demiBold;
      line-height: 50px;
      margin-bottom: 20px;
    }
  }

  .desc_body_box {
    padding-top: 20px;

    .back_text {
      font-size: 12px;
      display: flex;
      align-items: center;
      color: #f45c43;
      cursor: pointer;

      svg {
        margin-right: 10px;
      }
    }

    .details_box {
      padding-top: 20px;

      .title_text {
        font-size: 18px;
        font-family: $demiBold;
        line-height: 36px;
      }

      .text_desc {
        font-size: 14px;
        font-family: $medium;
        line-height: 30px;
      }

      .apply_box {
        margin-top: 30px;

        .button_box {
          position: absolute;
          position: absolute;
          top: 75%;
          left: 40%;
          margin-top: 30px;

          @include respond(mobile) {
            position: absolute;
            top: 72%;
            left: 20%;
          }

          @include respond(smScreen4) {
            position: absolute;
            top: 73.5%;
            left: 20%;
          }

          @include respond(smScreen3) {
            position: absolute;
            top: 73.5%;
            left: 20%;
          }

          .chakra-button {
            width: 240px;
          }
        }
      }
    }
  }
}

.score_progress {
  position: absolute;
  width: 98px;
  height: 102px;
}

.upload-container {
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    height: 34px;
  }
}

.apply_job_box {
  .button_wrap {
    margin-top: 30px;

    .chakra-button {
      width: 100%;
    }
  }
}

.circle {
  position: relative;
  width: 100px;
  height: 100px;

  svg {
    fill: none;
    stroke: #8a3ab9;
    stroke-linecap: round;
    stroke-width: 3;
    stroke-dasharray: 1;
    stroke-dashoffset: 0;
    animation: stroke-draw 6s ease-out infinite alternate;
    // animation: stroke-draw 6s cubic-bezier(0.77, 0, 0.175, 1) infinite alternate;
  }

  img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 70px;
    border-radius: 50%;
  }
}

.delete_modal {
  .modal-dialog {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .modal-content {
      border: none;
      border-radius: 30px;
      width: 365px;
      height: 150px;

      @include respond(mobile) {
        width: 300px;
      }

      @include respond(smMobile) {
        width: 250px;
      }

      .modal-body {
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding: 0;

        button {
          text-transform: none;
          font-family: $reqular;
          font-weight: bold;
          width: 100%;
          text-align: center;
          cursor: pointer;
          padding: 1.5rem 0rem;

          &:first-child {
            color: #d8463d;
            border-bottom: 0.5px solid #cccccc;
          }
        }
      }
    }
  }
}

.feature {
  padding: 0rem 1rem;

  .feature_title {
    font-family: $reqular;
    font-size: 24px;
    font-weight: bold;

    @include respond(smScreen1) {
      font-size: 34px;
    }

    @include respond(smScreen2) {
      font-size: 22px;
    }

    @include respond(smScreen3) {
      font-size: 18px;
    }

    @include respond(smScreen4) {
      font-size: 14px;
    }

    @include respond(smMobile) {
      font-size: 14px;
    }

    @include respond(mobile) {
      font-size: 14px;
    }
  }

  .feature_text {
    margin-top: 14px;
    font-family: $reqular;
    font-size: 75px;
    font-weight: bold;

    @include respond(smScreen1) {
      font-size: 56px;
    }

    @include respond(smScreen2) {
      font-size: 45px;
    }

    @include respond(smScreen3) {
      font-size: 40px;
    }

    @include respond(smScreen4) {
      font-size: 36px;
    }

    @include respond(mobile) {
      font-size: 32px;
    }

    @include respond(smMobile) {
      font-size: 30px;
    }
  }
}

.story_player::-webkit-media-controls-panel {
  background: none;
  position: absolute;
  top: 15px;
  width: 100%;
}

.story_player::-webkit-media-controls-current-time-display {
  position: absolute;
  right: 81px;
  top: 8px;
}

.story_player::-webkit-media-controls-time-remaining-display {
  position: absolute;
  right: 43px;
  top: 8px;
}

.story_player::-webkit-media-controls-volume-control-container {
  right: 105px;
  top: 8px;
}

.story_player::-webkit-media-controls-fullscreen-button {
  display: none;
}

.story_player::-webkit-media-controls-play-button {
  display: none;
}

.story_player::-internal-media-controls-button-panel {
  display: none;
}

.story_player::-webkit-media-controls-timeline {
  position: absolute;
  top: 4px;
  padding: 0px;
  width: 100%;
}

.deckbox {
  position: absolute;
  top: 20%;

  .text_feature {
    padding: 0;
    left: 62px;
    top: 0px;
    position: absolute;
    display: flex;

    @include respond(smScreen2) {
      left: 20px;
      top: 394px;
    }

    @include respond(smScreen3) {
      left: 20px;
      top: 394px;
    }

    @include respond(smScreen4) {
      left: 20px;
      top: 362px;
    }

    @include respond(mobile) {
      left: 20px;
      top: 325px;
    }
  }
}

@keyframes stroke-draw {
  from {
    stroke: #8a3ab9;
    stroke-dasharray: 1;
  }

  to {
    stroke: #cd486b;
    transform: rotate(180deg);
    stroke-dasharray: 8;
  }
}

// profile----sectionn----->>

.profile_body {
  .tab-wrapper {
    background: #fff;
    position: sticky;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 99;

    @include breakpoint(upto-mobile) {
      transform: translateY(-11px);
    }
  }

  .tab-content {
    overflow-x: hidden !important;
  }
}

.skills_wrap > .row {
  width: 100%;
  margin: 0 auto;
}

// profile----sectionn-end--here---->>

// feed---modal----start--here---->>

@include breakpoint(upto-landscape-tablet-992) {
  .player_modal {
    .modal-dialog {
      max-width: 90% !important;

      @include breakpoint(upto-tablet) {
        margin: 0.5rem auto;

        .media {
          .chakra-avatar {
            width: 2.8rem !important;
            height: 2.8rem !important;

            @include breakpoint(upto-mobile-mid) {
              width: 2.6rem !important;
              height: 2.6rem !important;

              .chakra-avatar__initials {
                font-size: 1.19rem;
              }
            }
          }

          .chakra-avatar__initials {
            font-size: 1.189rem;
            letter-spacing: 1.5px;
          }

          .icon.threedot_icon {
            width: 20px;
            height: 20px;
          }

          .media_details {
            h5 {
              font-size: 0.99em;
              font-weight: bold;
            }

            p {
              font-size: 0.872em;
              line-height: 1.3;
            }
          }
        }
      }
    }
  }

  .share-post-wrap {
    @include breakpoint(upto-mobile-mid) {
      padding: inherit !important;
    }

    .share_post_size {
      width: 95%;
      margin: 0 auto;
      height: auto !important;

      .player_col.right {
        .head span.chakra-avatar {
          width: 2.8rem !important;
          height: 2.8rem !important;

          @include breakpoint(upto-mobile-mid) {
            width: 2.6rem !important;
            height: 2.6rem !important;

            .chakra-avatar__initials {
              font-size: 1.19rem;
            }
          }
        }

        .chakra-avatar__initials {
          font-size: 1.189rem;
          letter-spacing: 1.5px;
        }

        .icon.threedot_icon {
          width: 20px;
          height: 20px;
        }

        .media_details {
          h5 {
            font-size: 0.99em;
            font-weight: bold;
          }

          p {
            font-size: 0.872em;
            line-height: 1.3;
          }
        }

        .comment_body {
          height: auto !important;
        }

        .feed_bottom {
          @include breakpoint(upto-mobile-320) {
            .like_comment_details {
              display: none;
            }
          }

          form {
            padding: 0 0.5rem 0.5rem !important;
            width: 100%;
            margin: 0 auto;

            @include breakpoint(upto-mobile-mid) {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

.report_submission_modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .report_submission_inner {
    margin-bottom: 15px;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    border: 2px solid #03bf54;
    position: relative;
  }

  .right_green_icon {
    height: 15px;
    width: 29px;
    position: absolute;
    top: 28%;
    left: 20%;
    border-bottom: 3px solid rgb(3, 191, 84);
    border-left: 3px solid rgb(3, 191, 84);
    transform: rotate(-44deg);
  }

  .first_heading {
    font-weight: bold;
    font-size: 19px;
    color: black;
    margin-bottom: 20px;
  }

  .main_content {
    font-size: 16px;
    text-align: center;
    line-height: 32px;

    p {
      margin-bottom: 0 !important;
    }
  }

  .done_button {
    width: 35%;
    margin-top: 20px;
    border-radius: 20px;
    color: white;
    background-color: #d8463d;
  }
}

.share-post-wrap {
  @include breakpoint(upto-XL-wide-screen) {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;

    .share-post-container {
      margin: 0 auto;
      width: 90%;

      .publick_navbar {
        width: 60%;
        margin: 0 auto;

        .logo_wrap {
          position: static !important;
          top: initial !important;
        }
      }

      .player-wrapper {
        video.react-player {
          height: 530px !important;
          object-fit: cover;

          @include breakpoint(upto-tablet) {
            height: auto !important;
          }
        }
      }

      .share_post_size {
        width: 50%;
        margin: 0 auto;
        height: calc(100vh - 106px) !important;
        display: flex;
        align-items: center;
        justify-content: center;

        .player_row {
          justify-content: center;
        }
      }

      @include breakpoint(upto-tablet) {
        .publick_navbar,
        .share_post_size {
          display: block;

          .navbar-brand {
            display: block;
          }

          width: 95% !important;

          @include breakpoint(upto-mobile) {
            width: 100% !important;
          }
        }
      }
    }

    @include breakpoint(upto-mobile-mid) {
      margin: 0;
      padding-top: 0.5rem !important;

      .player_modal video {
        border-top-left-radius: 1rem !important;
        border-top-right-radius: 1rem !important;
      }
    }
  }

  .share_post_size {
    width: initial;
    height: initial !important;

    @include breakpoint(upto-tablet) {
      height: auto !important;
    }
  }

  .comment_body {
    height: initial !important;
  }

  .player_col.right {
    position: relative;

    .body {
      .metadata {
        font-size: 0.8rem !important;
      }

      ul {
        li {
          cursor: pointer;

          p {
            font-size: 0.734rem !important;
          }
        }
      }
    }

    .media_details {
      font-weight: none;
      margin-left: 0.3rem;

      h2 {
        cursor: pointer;
        font-family: $medium;
      }

      p {
        cursor: pointer;
        font-family: $medium;
        font-size: 0.8rem;
        color: #707070;
      }
    }

    .view-all-comment {
      cursor: pointer;
      color: #363636;
      font-family: $demiBold;
      font-size: 11px;
      margin-left: 0.6rem;
      margin-top: 0.5rem;
    }

    .share-post-comment-wrap {
      form {
        position: absolute;
        bottom: 0;
        width: 100%;
        left: 0;
      }
    }
  }

  .player_row {
    grid-template-columns: repeat(2, 320px) !important;

    @include breakpoint(upto-tablet) {
      grid-template-columns: 1fr !important;

      .player_col.right {
        min-height: 24rem;
        border-top-right-radius: 0px !important;
      }
    }
  }
}

// feed---modal----end--here---->>

video.react-player:not(:root):fullscreen::backdrop //,.video-zoom
{
  background: #999 !important;
  object-fit: contain !important;
}

// ----Feature---section---->>>
.main-feature-wrapper {
  .feature {
    .feature_text {
      font-size: 3.3em;
      text-transform: capitalize;

      @include breakpoint(upto-desktop) {
        font-size: 3em;

        @include breakpoint(upto-landscape-tablet-992) {
          font-size: 2.7em;

          @include breakpoint(upto-tablet) {
            font-size: 2.66em;

            @include breakpoint(upto-mobile) {
              font-size: 2em;

              @include breakpoint(upto-mobile-mid) {
                font-size: 1.3em;
              }
            }
          }
        }
      }
    }
  }
}

// ----Feature---section----end---here-->>>
// ----header---Input---section----start---here-->>>
#basic-navbar-nav {
  .navbar-nav {
    width: 100%;
  }

  @include breakpoint(upto-mobile) {
    justify-content: flex-end !important;
    flex-wrap: nowrap;
    position: initial;

    .navbar-nav {
      justify-content: flex-end;
      margin-right: 0.5rem;
    }
  }
}

.header-search.header-input-wrapper {
  grid-template-columns: max-content;
  justify-content: flex-end;
  align-items: center;

  &::after {
    top: 0px;
  }
}

.mobile-header-wrap {
  display: none;
}

@include breakpoint(upto-mobile) {
  .mobile-header-wrap {
    display: block;

    .header-input-wrapper {
      display: block !important;

      .search-icon-wrapper {
        right: 0px;
        top: 5px;
      }
    }
  }
}

.mobile-search-icon {
  cursor: pointer;
  display: none;

  @include breakpoint(upto-mobile) {
    display: block;

    span {
      width: 35px;
      display: flex;
      align-items: center;

      i {
        font-size: 1.2em;
        color: #363636;
      }
    }
  }
}

.header-input-wrapper {
  margin-right: 2.2rem;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr max-content;
  position: relative;
  padding-right: 0.4rem;
  width: 72%;

  input {
    width: 100%;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 0.5px solid #dddddd;
    border-radius: 100px;
    height: 35px;
    padding: 0.2rem 1rem;
    outline: none;
    transition: $transition;
    color: #231f20;
    font-family: $reqular;
    font-size: 1em;
    caret-color: #dddddd;

    &:hover,
    &:focus {
      outline: none;
      transition: $transition;
      box-shadow: 0px 0px 2px 1px #0000000a;
    }

    &::placeholder {
      font-size: 1em;
      text-transform: capitalize;
      font-family: $reqular;
      color: #cccccc;
    }
  }

  @include breakpoint(upto-mobile) {
    position: absolute;
    top: 22px;
    left: 0;
    width: 59%;
    padding: 0;
    z-index: 99;
    margin: 0 auto;
    right: 0;

    &::after {
      display: none;
    }

    @include breakpoint(upto-mobile-mid) {
      width: 49%;
    }
  }

  &::after {
    content: "";
    width: 1.6px;
    height: 25px;
    background: #cbcbcb;
    position: absolute;
    right: -16px;
    top: 6px;
  }

  .search-icon-wrapper {
    width: 50px;
    text-align: center;
    vertical-align: middle;
    position: absolute;
    right: 8px;

    img {
      max-width: 51%;
      margin: 0 auto;
      border: 5px;
      cursor: pointer;
    }

    &::after {
      content: "";
      width: 1px;
      height: 25px;
      background: #cccccc;
      position: absolute;
      right: 46px;
      top: 0px;
    }
  }

  .ant-input-group-addon {
    i.fas.fa-search {
      color: #dddddd;
      font-size: 1.3rem;
      vertical-align: middle;
    }

    &:first-child {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
      background: #fff;
    }
  }

  .ant-input-affix-wrapper:hover,
  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused {
    border-color: #dddddd !important;
    box-shadow: none;
    border-right-width: 0;
    outline: none;
  }

  span.ant-input-affix-wrapper.ant-input-affix-wrapper-lg {
    background: #fff;
    margin-right: 0.3rem;
  }

  .ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  button.ant-btn.ant-btn-primary.ant-btn-lg.ant-input-search-button {
    background: #fff !important;
    border-color: #dddddd;
    padding: 0 0.5rem 0 0;
    border-left: none;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;

    span {
      background: #d8463d 0% 0% no-repeat padding-box;
      padding: 0.3rem 1rem;
      border-radius: 20px;
      font-size: 0.8125em;
    }
  }
}

.feed_col_middle {
  position: relative;
}

@include breakpoint(upto-tablet) {
  .post-modal-wrapper .player_row {
    grid-template-columns: 1fr !important;
  }
}

.Search-section {
  .feed_grid_row.css-144ovdp {
    margin-bottom: 20px;
    display: inherit !important;
    column-width: 250px;
    height: max-content;

    @include breakpoint(upto-XXL-wide-screen) {
      column-width: 330px;

      @include breakpoint(upto-XL-wide-screen) {
        column-gap: 20px;
        column-width: 296px;

        @include breakpoint(upto-landscape-tablet-992) {
          column-width: 230px;

          @include breakpoint(upto-mobile) {
            column-width: 150px;
            margin-bottom: 2rem;
            margin-top: 0.5rem;

            .feed_bottom {
              display: none;
            }

            @include breakpoint(upto-mobile-mid) {
              column-width: 130px;

              .post_head_avtar {
                span.chakra-avatar {
                  margin: 8px 5px 8px 0px !important;
                }
              }

              .post_head_un_time {
                width: 49px;

                .post_head_un {
                  font-size: 11px;
                  width: 55px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }
            }
          }
        }
      }
    }
  }

  .css-tuh9u2 {
    page-break-inside: avoid;
    -moz-column-break-inside: avoid;
    break-inside: avoid;
    margin-bottom: 1rem;

    @include breakpoint(upto-tablet) {
      white-space: break-spaces;
    }
  }

  position: relative;

  .closeSearch {
    position: absolute;
    right: 11px;
    cursor: pointer;
    top: 15px;
    width: 42px;
    height: 29px;
    text-align: center;
    color: #231f20;
    transition: $transition;

    &:hover {
      color: #000;
      transition: $transition;
    }
  }
}

.postsearch-spinner {
  position: absolute;
  top: 6px;
  right: 63px;
  width: 28px;
  height: 28px;
  background: #fff;
}

.cross-icon {
  right: 55px;

  i {
    font-size: 11px;
    color: #999;
    font-weight: 600;
    cursor: pointer;
    transition: $transition;

    &:hover {
      transition: $transition;
      color: #363636;
    }
  }
}

.feed-tab-wrapper {
  background: #fff;
  position: fixed;
  top: 0;
  width: 44%;
  height: 100%;
  flex: 1 1;
  right: 16.3rem;
  z-index: 999;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 0.4rem;
  animation: animeTopfade 0.9s forwards;

  @include breakpoint(upto-XXL-wide-screen) {
    right: 23rem;

    @include breakpoint(upto-XL-wide-screen) {
      right: 22rem;

      @include breakpoint(upto-Semi-XL-wide-screen) {
        right: 18rem;
        width: 47%;

        @include breakpoint(upto-X-wide-screen) {
          right: 16rem;
          width: 44%;

          @include breakpoint(upto-desktop) {
            right: 12px;
            width: 68%;
          }

          @include breakpoint(upto-desktop) {
            right: 12px;
            width: 68%;

            @include breakpoint(upto-landscape-tablet) {
              right: 12px;
              width: 67%;

              @include breakpoint(upto-landscape-tablet) {
                right: 12px;
                width: 65%;

                @include breakpoint(upto-tablet-800) {
                  right: 0;
                  width: 65%;
                  width: 97%;
                  margin: 0 auto;
                  left: 0;
                }
              }
            }
          }
        }
      }
    }
  }

  @keyframes animeTopfade {
    0% {
      top: 0;
      opacity: 0.37;
    }

    50% {
      opacity: 0.89;
    }

    90% {
      opacity: 99;
    }

    100% {
      top: 66px;
    }
  }

  @include breakpoint(upto-tablet) {
    left: 0;
    right: 0;
    width: 97%;
    margin: 0 auto;

    @include breakpoint(upto-mobile) {
      width: 96%;

      @keyframes animeTopfade {
        100% {
          top: 4.5rem;
        }
      }
    }
  }

  .list-item-wrapper {
    cursor: pointer;
  }

  ul {
    li {
      button {
        color: #cccccc;
        font-size: 1.125em;
        text-transform: capitalize;
        font-family: $reqular;
        letter-spacing: 0.6px;
        font-weight: 500;
        transition: $transition;
        border: none !important;

        &:hover,
        &:focus-visible {
          border: none;
          transition: $transition;
          color: currentColor;
          border-color: transparent !important;
          outline: none;
        }
      }

      .nav-link.active {
        font-size: 1.125em;
        text-transform: capitalize;
        font-family: $reqular;
        letter-spacing: 0.6px;
        font-weight: bold;
        border: none;
        color: #231f20;
        border-bottom: 2px solid #f25d49 !important;

        &:hover {
          border-bottom: 2px solid #f25d49 !important;
        }
      }
    }
  }

  .account-content {
    height: max-content; //calc(100vh - 430px);
    width: 95%;
    margin: 0 auto;

    .post-body-wrapper {
      @include breakpoint(upto-mobile) {
        padding: 0;
      }
    }

    .search-account-wrapper {
      height: calc(100vh - 430px);
      display: flex;
      align-items: center;
      justify-content: center;

      .nodata-wrapper {
        text-align: center;

        h2 {
          font-size: 1.375em;
          text-transform: capitalize;
          font-family: $medium;
          color: #231f20;
          font-weight: bold;
        }

        p {
          font-size: 1em;
          font-family: $reqular;
          color: #707070;
        }
      }
    }

    .account-data-wrapper {
      height: 250px;
      overflow: auto;
      overflow-x: hidden;
    }

    .account-search-content {
      .ant-list-item-meta-description {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 95%;
        font-family: $reqular;
        color: #707070;
        text-transform: capitalize;

        h4 {
          padding: 0.3rem 0 0;
        }
      }

      .ant-list-split .ant-list-item {
        border: none;

        .ant-list-item-meta {
          .ant-list-item-meta-content {
            border-bottom: 1px solid #ebebeb;
            padding-bottom: 0.5rem;
          }
        }

        &:last-child {
          .ant-list-item-meta {
            .ant-list-item-meta-content {
              border-bottom: none;
              padding-bottom: 0;
            }
          }
        }
      }
    }

    .account-search-content {
      .search-icon {
        text-align: center;
        display: block;

        i {
          font-size: 1.3em;
          color: #231f20;
        }
      }

      h4 {
        font-size: 1em;
        text-transform: capitalize;
        line-height: 1.4;
        color: #363636;
        font-family: $medium;

        a {
          text-decoration: none;
          transition: $transition;

          &:hover {
            color: #363636e8;
            transition: $transition;
          }
        }
      }

      .bio-heading {
        h2 {
          padding-left: 0.6rem;
          margin: 1rem 0;
        }
      }

      .search-account-heading {
        h2 {
          text-transform: capitalize;
          font-size: 1em;
          color: #d8463d;
          font-family: $reqular;
          font-weight: 600;
        }
      }

      .ant-list-item-meta {
        align-items: center;
      }

      // .ant-list-empty-text{
      //   display: none;
      // }
    }
  }
}

.post-body-wrapper {
  .feed_grid_col {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 18px;
    padding: 8px;
    position: relative;
  }

  .css-tuh9u2 {
    &:last-child {
      .feed_grid_col {
        margin-bottom: 0.5rem;
      }
    }
  }

  .post-search-wrap {
    height: calc(100vh - 150px);
    overflow: auto;
    overflow-x: hidden;
    padding: 0 0.5rem;

    @include breakpoint(upto-mobile-375) {
      padding: 0 0.2rem;
    }

    .feed_body {
      .feed_content {
        width: 100%;
        position: relative;
        overflow: hidden;
        border-radius: 12px;

        .users {
          border-radius: 12px;
          position: absolute;
          bottom: 0;
          z-index: 1;
          left: 0;
          display: flex;
          justify-content: space-between;
          width: 100%;
          padding: 0 10px 10px;
          align-items: flex-end;

          .views_count {
            color: #fff;
            font-size: 12px;
            text-transform: capitalize;
            font-family: $reqular;
            font-weight: bold;
          }

          ul {
            li {
              display: flex;
              align-items: center;
              gap: 0 0.5rem;

              strong {
                color: #fff;
                font-size: 12px;
                text-transform: capitalize;
                font-family: $reqular;
                font-weight: bold;
              }

              &:first-child {
                margin-bottom: 10px;
              }
            }
          }
        }
      }
    }
  }
}

// ----header---Input---section----end---here-->>>

// ----Story---view---responsive ---start--here-->>>>>
@include breakpoint(upto-mobile) {
  .story_modal {
    .modal-dialog
      .modal-content
      .modal-body
      .react-3d-carousel
      .slider-container
      .slider-content {
      width: 100%;
    }

    .react-3d-carousel .slider-container .slider-content .slider-single.active {
      background: #2e2d2b7a;
      padding: 1rem 0 2.5rem;
      transform: translateY(-13px);

      @include breakpoint(upto-mobile-mid) {
        background: #2e2d2b7a;
        padding: 1rem 0 3rem;
      }
    }

    .react-3d-carousel .slider-container {
      left: 45%;
      width: 100%;
      padding: 1rem 0;
      height: 100%;
    }

    .modal-dialog-centered {
      min-height: calc(100%) !important;
    }

    .status-logo-wrap {
      display: none;
    }

    .close_modal {
      right: 13px !important;
      top: -14px;
    }

    .modal-dialog .modal-content .modal-body .react-3d-carousel {
      height: calc(100vh - 30px) !important;
    }

    .modal-dialog {
      overflow: hidden;
    }

    .slider-container {
      top: 1%;
    }
  }
}

// ----Story---view---responsive --end--here--->>>>>
// ----Post_modal---section --start--here--->>>>>
.post-modal-wrapper {
  .player_row {
    display: grid;
    grid-template-columns: 320px 320px;
  }

  .close_modal {
    right: -211px;
  }
}

// ----Post_modal---section --end--here--->>>>>

.hide_vlaues {
  visibility: hidden;
}

.choose-device-modal {
  .modal-dialog {
    margin: 0 auto;
    width: 355px !important;
    .close_modal {
      right: 15px !important;

      .icon {
        width: 15px;
        height: 15px;
      }
      .cross:before {
        filter: brightness(0.5);
      }
    }
  }
}




@import "./responsive.scss";
