@import "../common/breakpoint.scss";
@import "../variables.scss";
@import "../mixin.scss";


.creator-wrapper {
    background: transparent linear-gradient(180deg, #FFFAED 0%, #FFFDF8 39%, #FFFEFC 70%, #FFFFFF 100%) 0% 0% no-repeat padding-box;

    .publick_navbar {
        width: 90%;
        margin: 0 auto;

        .navbar-brand {
            display: inline-block;

            .logo_wrap {
                position: initial;
                left: auto;
                top: auto;
            }
        }
    }

    .creator-content-wrapper {
        .creator-banner {
            padding: 4rem 0;
            background: transparent linear-gradient(257deg, #D8463D 0%, #F45C43 47%, #F3AF41 100%) 0% 0% no-repeat padding-box;
            text-align: center;
            @include breakpoint(upto-mobile){ 
                padding: 1.4rem 0;
            }
            p {
                color: #FFFFFF;
                font-size: 2.6em;
                text-transform: capitalize;
                font-family: $demiBold;
                @include breakpoint(upto-tablet){
                    font-size: 2em;
                    @include breakpoint(upto-mobile){
                        font-size: 1.4em;
                        @include breakpoint(upto-mobile-375){
                            font-size: 1.3em;
                        }
                    }
                }
            }

            button {
                padding: .5rem 2.5rem;
                color: #FFFFFF;
                background: #231F20;
                border-radius: 100px;
                transition: $transition;
                font-family: $demiBold;
                text-transform: capitalize;
                letter-spacing: .5px;
                margin-top: 1rem; 
                font-size: 1em;
                box-shadow: 0px 2px 4px #00000040;
                @include breakpoint (upto-mobile-mid){
                    font-size: .875em;
                    padding: .4rem 2.1rem;
                }
                a{
                    text-decoration: none;
                    color: #FFFFFF;
                }
                &:hover {
                    transition: $transition;
                    opacity: .78!important; 
                }
            }
        }

        .creator-card-wrap {
            max-width: 90%;
            margin: 2rem auto;
            @include breakpoint(upto-mobile-375){
                margin: 1rem auto 0;
            }
            .creator-content-wrap {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                align-items: center;
                grid-auto-flow: dense;
                grid-gap: 2.5rem;
                width: 70%;
                margin: 0 auto; 
                @include breakpoint(upto-tablet){
                    width: 90%;
                    margin: 0 0 .5rem;
                    @include breakpoint(upto-mobile){
                        grid-template-columns: repeat(1, 1fr);
                        grid-auto-flow:initial;
                        grid-gap: 1.5rem;
                        margin: 0 auto;
                        @include breakpoint(upto-mobile-375){
                            grid-gap: .5rem;
                        }
                    }
                }
                .creator-card-left{
                    figure{
                        img{
                            max-width: 70%;
                            height: 100%;
                            // margin: 0 auto;
                            transition: $transition;
                            @include breakpoint(upto-tablet){
                                max-width: 100%;
                            }
                            &:hover {
                                transform: scale(1.004)rotate(.55deg);
                                transition: $transition;
                            }
                        }
                    }
                }
                .creator-card-right{
                    h3{
                        font-size: 3em;
                        text-transform: capitalize;
                        line-height: 1.5;
                        word-spacing: 1px;
                        letter-spacing: .5px;
                        margin: .5rem 0;
                        font-family:$demiBold;
                        color: #363636;
                        @include breakpoint(upto-tablet){
                            font-size: 1.9em;
                            @include breakpoint(upto-mobile){
                                font-size: 1.6em;
                                @include breakpoint (upto-mobile-mid){
                                    font-size: 1.4em;
                                    line-height: 1.4;
                                }
                            }
                        }
                    }
                    p{
                        color: #363636;
                        font-size: 1.375em;
                        // text-transform: capitalize;
                        font-family: $reqular;
                        padding: .4rem 0;
                        @include breakpoint(upto-tablet){
                            font-size: 1em;
                            @include breakpoint(upto-mobile-mid){
                                font-size: .875em;
                            }
                        }
                        b{
                            font-weight: bold;
                            padding: 0 .2rem;
                        }
                    }
                }
            }
        }
        .card-wrap-reverse{
            .creator-card-left{
                grid-column: 2;
                @include breakpoint(upto-tablet){ 
                    grid-column: 1;
                }
            }
            .creator-card-right{
                grid-column: 1;
            }
        }
    }
    footer{
        position: relative!important;
        bottom: inherit!important;
    }
}